import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import SearchLicensePlate from './SearchLicensePlate.js';
import  ornamentZero from 'images/background_auto_valid.jpeg';

const useStyles = makeStyles((theme) => ({
    root:{
      // height: '570px',
      // backgroundColor: '#012f47'
      backgroundImage: `url('${ornamentZero}')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      [theme.breakpoints.up('xl')]: {
        // height: '950px',
      },
      [theme.breakpoints.down('xl')]: {
      // width: '175px',
      },
      [theme.breakpoints.down('lg')]: {
      // width: '650px',
      },
      [theme.breakpoints.down('md')]: {
      // width: 500,
      },
      [theme.breakpoints.down('sm')]: {
      // width: 400,
      },
      [theme.breakpoints.down('xs')]: {
      // width: '100px',
      }
    },
    leftGrid:{
      [theme.breakpoints.up('xl')]: {
      // width: '175px',
      },
      [theme.breakpoints.down('xl')]: {
      // width: '175px',
      },
      [theme.breakpoints.down('lg')]: {
      // width: '650px',
      },
      [theme.breakpoints.down('md')]: {
      // width: 500,
      },
      [theme.breakpoints.down('sm')]: {
      // width: 400,
      },
      [theme.breakpoints.down('xs')]: {
      // width: '100px',
      }
    }, 
    blockLeftGrid:{
      color: '#FFF',
    },
    title:{
      textAlign: 'left',
      fontFamily: 'noyh',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      [theme.breakpoints.up('xl')]: {
        marginTop: '30px',
        marginLeft: '205px',
        fontSize: '36px',
      },
      [theme.breakpoints.down('lg')]: {
        marginTop: '45px',
        marginLeft: '65px',
        fontSize: '22px',
      },
      [theme.breakpoints.down('md')]: {
        marginTop: '30px',
        marginLeft: '55px',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '22px',
        marginLeft: '45px',
        fontSize: '14px',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '15px',
        marginLeft: '37px',
        fontSize: '16px',
      },
    },
    subTititle:{
      textAlign: 'left',
      marginBottom: '15px',
      fontFamily: 'noyh',
      fontWeight: 900,
      [theme.breakpoints.up('xl')]: {
        marginTop: '35px',
        marginLeft: '205px',
        fontSize: '85px',
        lineHeight: '95px',
      },
      [theme.breakpoints.down('lg')]: {
        maxWidth: '450px',
        marginTop: '25px',
        marginLeft: '65px',
        fontSize: '70px',
        lineHeight: '65px',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '435px',
        marginLeft: '55px',
        fontSize: '60px',
        lineHeight: '40px',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '300px',
        marginLeft: '45px',
        fontSize: '32px',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '493px',
        marginLeft: '37px',
        fontSize: '40px',
      }
    },
    paragraph:{
      textAlign: 'left',
      marginBottom: '15px',
      fontFamily: 'Noyh',
      fontWeight: 'normal',
      marginLeft: '65px',
      fontSize: '18px',
      [theme.breakpoints.up('xl')]: {
        marginLeft: '205px',
      },
      [theme.breakpoints.down('lg')]: {
        maxWidth: '400px',
        marginLeft: '65px',
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '55px',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '45px',
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: '37px',
      }
    },
    rightGrid:{
      // backgroundImage: `url('${ornamentZero}')`,
      // backgroundRepeat: 'no-repeat',
      // backgroundSize: '140%',
      backgroundPosition: 'right',
      [theme.breakpoints.up('xl')]: {
        // width: '175px',
      },
      [theme.breakpoints.down('xl')]: {
      // width: '175px',
      },
      [theme.breakpoints.down('lg')]: {
      // width: '200px',
      },
      [theme.breakpoints.down('md')]: {
      // width: 500,
      },
      [theme.breakpoints.down('sm')]: {
      },
      [theme.breakpoints.down('xs')]: {
        height: '30px',
      },
    },
}));
  

export default function SearchContainer() {
    const classes = useStyles();
    return (
      <Grid className={classes.root} xs={12} sm={12} md={12} container>
          <Grid item xs={12} sm={6} md={6} className={classes.leftGrid}>
            <div className={classes.blockLeftGrid}>
              {/* <Typography className={classes.title}>
                ALIAS CONSEQUATUR
              </Typography> */}
              <Typography className={classes.subTititle}>
                Pensando em comprar um carro usado?
              </Typography>
              <Typography className={classes.paragraph}>
                Antes de comprar faça uma vistoria e fique
                sabendo se o mesmo já foi sinistrado,
                roubado, batido, adulterado, leiloado e
                muito mais!
              </Typography>
              <SearchLicensePlate/>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.rightGrid}/>
      </Grid>
    )
}
import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper } from "@material-ui/core";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LoadingButton from "../components/LoadingButton";
import ModalForm from "../components/ModalForm";
import Loading from "../components/Loading";
import confirmationIcon from "images/confirmation_icon.svg";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  schedulingContainer: {
    textAlign: "left",
    padding: "30px",
  },
  confirmationBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    "& img": {
      marginBottom: "30px",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "22px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  information: {
    color: "#333333",
    marginTop: "30px",
  },
  subtitle: {
    fontSize: "Roboto",
    letterSpacing: "0px",
    color: "#536777",
    opacity: "1",
    marginTop: "20px",
  },
  description: {
    color: "#144167",
  },
  confirmationIcon: {
    [theme.breakpoints.up("xl")]: {
      width: "30%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
  },
  scheduleButton: {
    width: "100%",
    marginTop: "10px",
    marginBottom: "50px",
    borderRadius: "10px",
    opacity: 1,
    "& span": {
      fontSize: 16,
      textTransform: "none",
    },
  },
  footer: {
    width: "100%",
    bottom: "0",
  },
}));

export default function SchedulingConfirmation(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const scheduleConfirmation = location?.state?.schedule || null;
  const vehicleInspectionId = location?.state?.vehicleInspectionId;
  const [isLoading, setIsLoading] = useState(false);

  // Save schedule
  function saveSchedulingConfirmation() {
    setIsLoading(true);
    axios
      .post("/api/v1/vehicle_inspection/scheduling", {
        schedule: scheduleConfirmation,
        vehicleInspectionId: vehicleInspectionId,
      })
      .then((res) => {
        let orderId = res.data.order_id;
        history.push({
          pathname: `/order/${orderId}`,
          state: {
            vehicleInspectionId: vehicleInspectionId,
          },
        });
      })
      .catch(() => {
        history.push({
          pathname: "/integration_error",
        });
      })
      .then(() => {
        setIsLoading(false);
      });
  }

  // Fomart date to dd/mm/yyyy
  function formatDate(date) {
    let dateFormated = new Date(date).toLocaleDateString("pt-br", {
      timeZone: "UTC",
    });
    return dateFormated;
  }

  return (
    <div>
      <Header currentUser={props.currentUser} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ margin: "150px 0" }}
      >
        <Grid item xs={10} sm={6}>
          <Paper>
            <Grid container className={classes.schedulingContainer}>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                className={classes.confirmationBox}
              >
                <img
                  src={confirmationIcon}
                  className={classes.confirmationIcon}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={9}>
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    Confirmação de agendamento
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.information}>
                    Verifique se os dados inseridos estão corretos
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={12} className={classes.subtitle}>
                    <Typography>Local</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.description}>
                      {scheduleConfirmation?.inspectionCenter?.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <Typography className={classes.subtitle}>Data</Typography>
                    <Typography className={classes.description}>
                      {formatDate(scheduleConfirmation?.date)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography className={classes.subtitle}>
                      Horário
                    </Typography>
                    <Typography className={classes.description}>
                      {scheduleConfirmation?.hour.replace(/:00/, "")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={3}>
                  <LoadingButton
                    variant="contained"
                    className={classes.scheduleButton}
                    style={{ backgroundColor: "#239CC4", color: "#fff" }}
                    onClick={saveSchedulingConfirmation}
                    fullwidth
                  >
                    CONFIRMAR
                  </LoadingButton>
                </Grid>
                <Grid item xs={3}>
                  <LoadingButton
                    variant="outlined"
                    className={classes.scheduleButton}
                    style={{ border: "2px solid #239CC4", color: "#239CC4" }}
                    onClick={() => history.push("/scheduling")}
                    fullwidth
                  >
                    ALTERAR
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <ModalForm open={isLoading}>
        <Loading />
      </ModalForm>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
}

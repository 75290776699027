import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Hidden,
  Grid,
  Container,
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Link,
  Button,
} from "@material-ui/core";
import axios from "axios";
import { Link as ScrollLink } from "react-scroll";
import logoIcon from "images/marca_icon.svg";
import MenuIcon from "@material-ui/icons/Menu";
import ModalForm from "../components/ModalForm";
import RegisterForm from "../components/RegisterForm";
import LoginForm from "../components/LoginForm";
import SupportForm from "../components/SupportForm";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#012f47",
    boxShadow: "none",
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 90,
  },
  logo: {
    textAlign: "left",
    [theme.breakpoints.up("xl")]: {
      marginLeft: "170px",
      marginTop: "20px",
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: "40px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "170px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "150px",
      margin: "unset",
    },
  },
  desktopMenu: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    fontFamily: "Source Sans Pro",
    "& a": {
      fontSize: 20,
    },
  },
  desktopMenuItem: {
    color: "#fff",
    textDecoration: "none",
  },
  contactUs: {
    marginLeft: "50px",
    border: "1px solid #FFFFFF4B",
    borderRadius: "25px",
    padding: "15px 25px 13px",
    "& a": {
      color: "inherit",
      textDecoration: "none",
    },
  },
  mobileMenuItem: {
    color: "#000",
    textDecoration: "none",
  },
  userMenu: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
  },
  menu: {
    "& .MuiPaper-root": {
      left: "unset !important",
      right: 15,
    },
  },
  userNameMenu: {
    textAlign: "right",
    fontSize: 20,
  },
}));

export default function Header(props) {
  const classes = useStyles();

  // Data current user
  const currentUser = props.currentUser || [];

  // Current location
  const location = useLocation();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [support, setSupport] = React.useState(false);

  // Definition of the modal component
  const [formSection, setFormSection] = React.useState("register");

  function jumpToLogin() {
    setFormSection("login");
  }

  function jumpToRegister() {
    setFormSection("register");
  }

  const userMenuHandleClick = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const userMenuHandleClose = () => {
    setUserMenuAnchorEl(null);
  };

  const menuHandleClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const menuHandleClose = () => {
    setMenuAnchorEl(null);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSupportOpen = () => {
    setSupport(true);
  };

  // Redireciona após o login ou cadastro
  useEffect(() => {
    if (Object.keys(currentUser).length > 0 && open) {
      window.location = "/orders";
      setOpen(false);
    }
  }, [currentUser]);

  // Logout method
  function handleLogout() {
    axios.delete(`/users/sign_out.json`).then(function (res) {
      window.location = "/";
    });
  }

  function HomeDesktopMenu() {
    return (
      <div className={classes.desktopMenu}>
        <Typography>
          {Object.keys(currentUser).length > 0 ? (
            <Link className={classes.desktopMenuItem} href="/orders">
              Minha Conta
            </Link>
          ) : (
            <Link
              className={classes.desktopMenuItem}
              href="#"
              onClick={handleOpen}
            >
              Minha Conta
            </Link>
          )}
        </Typography>
        <Typography>
          <Link>
            <ScrollLink
              className={classes.desktopMenuItem}
              to="aboutUs"
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
              href="#aboutUs"
            >
              Sobre Nós
            </ScrollLink>
          </Link>
        </Typography>
        <Typography>
          <Link className={classes.contactUs} color="inherit">
            <ScrollLink onClick={handleSupportOpen} href="#">
              Fale Conosco
            </ScrollLink>
          </Link>
        </Typography>
      </div>
    );
  }

  function HomeMobileMenu() {
    return (
      <div className={classes.mobileMenu}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={menuHandleClick}
        >
          <MenuIcon style={{ color: "#fff" }} />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={menuHandleClose}
          className={classes.menu}
        >
          <MenuItem onClick={menuHandleClose}>
            {Object.keys(currentUser).length > 0 ? (
              <Link className={classes.mobileMenuItem} href="/account">
                Minha Conta
              </Link>
            ) : (
              <Link className={classes.mobileMenuItem} onClick={handleOpen}>
                Minha Conta
              </Link>
            )}
          </MenuItem>
          <MenuItem onClick={menuHandleClose}>
            <ScrollLink
              className={classes.mobileMenuItem}
              to="aboutUs"
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
              href="#aboutUs"
            >
              Sobre Nós
            </ScrollLink>
          </MenuItem>
          <MenuItem onClick={menuHandleClose}>
            <ScrollLink
              className={classes.mobileMenuItem}
              onClick={handleSupportOpen}
              href="#"
            >
              Fale Conosco
            </ScrollLink>
          </MenuItem>
        </Menu>
      </div>
    );
  }

  function UserMenu() {
    return (
      <div className={classes.userMenu}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={userMenuHandleClick}
        >
          <Avatar alt="avatar" />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={userMenuAnchorEl}
          keepMounted
          open={Boolean(userMenuAnchorEl)}
          onClose={userMenuHandleClose}
          className={classes.menu}
        >
          {/* remover sublinhado */}
          <Link className={classes.mobileMenuItem} href="/account">
            <MenuItem onClick={userMenuHandleClose}>Minha Conta</MenuItem>
          </Link>
          <Link className={classes.mobileMenuItem} href="/orders">
            <MenuItem onClick={userMenuHandleClose}>Meus Pedidos</MenuItem>
          </Link>
          <Link className={classes.mobileMenuItem} onClick={handleLogout}>
            <MenuItem onClick={userMenuHandleClose}>Sair</MenuItem>
          </Link>
        </Menu>
        <Hidden xsDown>
          <Typography className={classes.userNameMenu} variant="body1">
            <Link
              style={{
                color: "#fff",
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={userMenuHandleClick}
            >
              {currentUser.name}
            </Link>
          </Typography>
        </Hidden>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <AppBar position="static" className={classes.root}>
          <Toolbar>
            <Grid className={classes.header} container>
              {/* Logo */}
              <Grid xs={10} sm={9} md={4} xl={3} className={classes.logo} item>
                <Link href="/">
                  <img src={logoIcon} />
                </Link>
              </Grid>
              <Grid Grid xs={2} sm={2} md={7} xl={7} item>
                {/* Home menu*/}
                {(location.pathname == "/" ||
                  location.pathname == "/reset_password" ||
                  location.pathname == "/request_password_reset") && (
                  <div>
                    <Hidden smDown>
                      <HomeDesktopMenu />
                    </Hidden>
                    <Hidden mdUp>
                      <HomeMobileMenu />
                    </Hidden>
                  </div>
                )}
                {/* User menu */}
                {Object.keys(currentUser).length > 0 &&
                  (location.pathname != "/" ||
                    location.pathname == "/reset_password" ||
                    location.pathname == "/request_password_reset") && (
                    <UserMenu />
                  )}
              </Grid>
            </Grid>
          </Toolbar>
          <ModalForm open={open}>
            <div style={{ display: "contents" }}>
              {formSection == "register" && (
                <RegisterForm
                  jumpToLogin={jumpToLogin}
                  currentUser={currentUser}
                />
              )}
              {formSection == "login" && (
                <LoginForm
                  jumpToRegister={jumpToRegister}
                  currentUser={currentUser}
                />
              )}
            </div>
          </ModalForm>
          <ModalForm open={support}>
            <SupportForm />
          </ModalForm>
        </AppBar>
      </Container>
    </div>
  );
}

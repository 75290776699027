import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { FormControl, InputBase, Grid, Button } from "@material-ui/core";
import swal from "sweetalert";
import axios from "axios";
import { TextMaskLicensePlate } from "./TextMaskCustom";
import ModalForm from "../components/ModalForm";
import errorIcon from "images/error-icon.svg";
import infoIcon from "images/info-icon.svg";
import Loading from "../components/Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xl")]: {
      marginBottom: "45px",
    },
    [theme.breakpoints.down("lg")]: {
      marginBottom: "190px",
      maxWidth: "600px",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "40px",
      marginLeft: "18px",
      maxWidth: "495px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "34px",
      marginBottom: "25px",
      maxWidth: "330px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "unset",
      maxWidth: "unset",
    },
  },
  InputContainer: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
    },
  },
  inputForm: {
    borderRadius: "30px",
    position: "relative",
    backgroundColor: "#FFF",
    "& input": {
      marginTop: "5px",
      color: "#0C1364",
      height: "43px",
      fontFamily: "Noyh",
      fontWeight: 900,
      textTransform: "uppercase",
      "&::placeholder": {
        color: "#000",
      },
      [theme.breakpoints.up("xl")]: {
        width: "490px",
        paddingLeft: "30px",
      },
      [theme.breakpoints.down("lg")]: {
        width: "315px",
        paddingLeft: "150px",
      },
      [theme.breakpoints.down("md")]: {
        width: "290px",
        paddingLeft: "125px",
      },
      [theme.breakpoints.down("sm")]: {
        // border: '1px solid blue',
        width: "300px",
        paddingLeft: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "273px",
        paddingLeft: "15px",
        "&::placeholder": {},
      },
    },
  },
  searchButton: {
    marginTop: "3px",
    width: "117px",
    height: "39px",
    color: "#FFF",
    position: "absolute",
    right: 15,
    top: 8,
    backgroundColor: "#239cc4",
    borderRadius: "13px",
    "& span": {
      fontSize: 14,
      fontFamily: "Open Sans",
      fontWeight: "bold",
    },
  },
}));

export default function SearchInspection() {
  const classes = useStyles();
  let history = useHistory();

  // Loading icon
  const [loading, setLoading] = useState(false);

  // Vehicle filds
  const [vehicle, setVehicle] = useState({
    license_plate: "",
    current_document: "",
    state: "",
  });

  // Vehicle inspection search
  function SearchInspection(e) {
    e.preventDefault();
    // Loading
    setLoading(true);
    let license_plate = vehicle.license_plate.replace("-", "");
    axios
      .get(
        `/api/v1/vehicle_inspection/consult_inspection?license_plate=${license_plate}`
      )
      .then(function (res) {
        let occultReport = res.data.report;
        // Redirects to inspection screen
        history.push({
          pathname: "/vehicle_inspection",
          state: {
            occultReport,
          },
        });
      })
      .catch(function (error) {
        // Loading
        setLoading(false);
        if (error.response.status == 422) {
          swal({
            title: "Error",
            text: `Placa ${error.response.data.errors.license_plate.toString()}.`,
            icon: errorIcon,
          });
        } else if (error.response.status == 404) {
          swal({
            title: "Info",
            text: error.response.data.info,
            icon: infoIcon,
          });
        } else {
          console.log(error.response);
        }
      });
  }

  return (
    <div className={classes.root}>
      <Grid sm={12} container>
        <Grid sm={12} item className={classes.InputContainer}>
          {/* <Tooltip title="Consulte o laudo do veículo informando a placa" placement="bottom-end" TransitionComponent={Zoom} arrow> */}
          <FormControl component="form" className={classes.inputForm}>
            <InputBase
              id="license_plate"
              name="license_plate"
              required="true"
              autoComplete="off"
              value={vehicle["license_plate"]}
              onChange={(e) =>
                setVehicle({
                  ...vehicle,
                  license_plate: e.target.value.toUpperCase(),
                })
              }
              label="Placa"
              placeholder="Digite sua placa"
              inputComponent={TextMaskLicensePlate}
            />
            <Button
              onClick={SearchInspection}
              className={classes.searchButton}
              variant="contained"
            >
              CONSULTAR
            </Button>
          </FormControl>
          {/* </Tooltip> */}
        </Grid>
      </Grid>
      <ModalForm open={loading}>
        <Loading />
      </ModalForm>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Grid, Button, Typography, Hidden } from "@material-ui/core";
import passswordIcon from "images/password_icon.svg";
import passswordConfimationIcon from "images/password_confirmation_icon.svg";
import ModalForm from "../components/ModalForm";
import Loading from "../components/Loading";
import swal from "sweetalert";
import sucessIcon from "images/sucess-icon.svg";
import errorIcon from "images/error-icon.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "220px",
  },
  profileContainer: {
    display: "flex",
    flexDirection: "column",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  },
  imgBox: {
    marginRight: 25,
  },
  inputBox: {
    display: "contents",
  },
  buttonSubimit: {
    textAlign: "left",
    marginLeft: 54,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginLeft: "unset",
    },
    "& button": {
      color: "#fff",
      width: "100px",
      borderRadius: "10px",
      fontWeight: 900,
      backgroundColor: "#239CC4",
    },
  },
  textField: {
    "& label.Mui-focused": {
      color: "#536777",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset, &:hover fieldset": {
        borderColor: "#05CC98",
        opacity: 0.24,
      },
    },
  },
}));

export default function ChangePasswordForm(props) {
  const classes = useStyles();

  // Data current user
  const currentUser = (props.currentUser && props && props.currentUser) || [];

  const [user, setUser] = useState({
    id: currentUser.id,
    password: "",
    password_confirmation: "",
    current_password: "",
  });

  // Loading
  const [loading, setLoading] = useState(false);

  // Erros
  const [userErrors, setUserErrors] = useState({});

  // Change data user info
  function changePassword(e) {
    setLoading(true);
    e.preventDefault();
    props
      .updateUser(user)
      .then((res) => {
        swal({
          title: "Sucesso",
          text: `${res.data.message}`,
          icon: `${sucessIcon}`,
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      })
      .catch((err) => {
        swal({
          title: "Erro!",
          text: `${err.response.data.message}`,
          icon: `${errorIcon}`,
        });
        setUserErrors(err.response.data.errors);
      })
      .then(() => {
        setLoading(false);
      });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  }

  function fieldsEmpty() {
    if (
      user.password === "" ||
      user.password_confirmation === "" ||
      user.current_password === ""
    )
      return true;
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <form onSubmit={changePassword} noValidate style={{ width: "100%" }}>
          <Grid xs={12} className={classes.profileContainer} item>
            <div className={classes.inputContainer}>
              <Hidden xsDown>
                <div className={classes.imgBox}>
                  <img src={passswordIcon} />
                </div>
              </Hidden>

              <div className={classes.inputBox}>
                <TextField
                  id="outlined-basic"
                  name="current_password"
                  type="password"
                  label="Senha atual"
                  variant="outlined"
                  className={classes.textField}
                  error={userErrors.current_password ? true : false}
                  helperText={
                    userErrors.current_password
                      ? userErrors.current_password.join(", ")
                      : ""
                  }
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </div>
            </div>

            <div className={classes.inputContainer}>
              <Hidden xsDown>
                <div className={classes.imgBox}>
                  <img src={passswordIcon} />
                </div>
              </Hidden>

              <div className={classes.inputBox}>
                <TextField
                  id="outlined-basic"
                  name="password"
                  type="password"
                  label="Nova senha"
                  variant="outlined"
                  className={classes.textField}
                  onChange={handleChange}
                  error={userErrors.password ? true : false}
                  helperText={
                    userErrors.password ? userErrors.password.join(", ") : ""
                  }
                  fullWidth
                  required
                />
              </div>
            </div>

            <div className={classes.inputContainer}>
              <Hidden xsDown>
                <div className={classes.imgBox}>
                  <img src={passswordConfimationIcon} />
                </div>
              </Hidden>

              <div className={classes.inputBox}>
                <TextField
                  id="outlined-basic"
                  name="password_confirmation"
                  type="password"
                  label="Confirmação"
                  variant="outlined"
                  className={classes.textField}
                  error={userErrors.password_confirmation ? true : false}
                  helperText={
                    userErrors.password_confirmation
                      ? userErrors.password_confirmation.join(", ")
                      : ""
                  }
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </div>
            </div>
          </Grid>

          <div className={classes.buttonSubimit}>
            <Button
              variant="contained"
              size="large"
              type="submit"
              disableElevation
              disabled={fieldsEmpty()}
            >
              Enviar
            </Button>
          </div>
        </form>
      </Grid>
      <ModalForm open={loading}>
        <Loading />
      </ModalForm>
    </div>
  );
}

import axiosClient from './axiosClient.js'
import axios from 'axios';
import { useLocation } from 'react-router-dom'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const authAxiosClient = axios.create({
  baseURL: ''
});

// NOTE: Pede json como retorno
authAxiosClient.defaults.headers.common['Accept'] = 'application/json';

// NOTE:
// Esta configuração monitora as respostas das requisições
// Se receber alguma resposta com status 401 entendo que o usuário não está logado a API
// Sendo assim redireciono a página para a rota ROOT, Onde está a página de login
authAxiosClient.interceptors.response.use(function (response) {
  console.debug('Request Successful!', response);
  return response.data;
}, function (error) {
  console.error('Request Failed:', error.config);

  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // if (error.message == 'Network Error') {
      // You can treat network error globally here
    // }
    console.log(error.message);
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  
  return Promise.reject(error.response || error.message);
});

/**
 * Authenticate the user
 * @param {Object} user - The user trying to sign in
 * @param {string} user.email - User email.
 * @param {string} user.password - User password.
 * @param {string} user.seach_id - Search id cunsulted
 */
function signIn(user) {
  // Need to use default axios lib because i don't want 
  //  interceptors to work for this request in particular
  //  method because the way rails returns 401 when e-mail
  //  and password don't match.
  return axios.post('/users/sign_in', { user: user }, { headers: { 'Accept': 'application/json' } });
}

/**
 * Create an user account
 * @param {Object} user
 * @param {File} user.avatar
 * @param {string} user.name
 * @param {string} user.email
 * @param {string} user.password
 * @param {string} user.password_confirmation
 */
function signUp(user) {
  let formData = new FormData();
  // formData.append('user[avatar]', user.avatar || '');
  formData.append('user[name]', user.name);
  formData.append('user[last_name]', user.last_name);
  formData.append('user[email]', user.email);
  formData.append('user[document]', user.document);
  formData.append('user[phone]', user.phone);
  formData.append('user[password]', user.password);
  formData.append('user[password_confirmation]', user.password_confirmation);
  formData.append('user[search_id]', user.searchId);
  
  return axiosClient.post('/api/v1/users', 
    formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
}

/**
 * Request a password reset email
 * @param {Object} user
 * @param {string} user.email
 */
function requestPasswordReset(user) {
  return axiosClient.post('/adm/users/password', { user: user });
}

function requestPasswordResetByPin({ phone }) {
  return axiosClient.post('/recovery_pin', { recovery: { phone: phone } });
}

/**
 * Reset user password
 * @param {Object} user
 * @param {string} user.password
 * @param {string} user.password_confirmation
 */
function resetPassword(user) {
  const query = new URLSearchParams(window.location.search);
  return authAxiosClient.put('/adm/users/password', { user: { ...user, reset_password_token: query.get("reset_password_token")} });
}

function resetPasswordByPin(user) {
  const { token, phone, password, passwordConfirmation } = user
  return authAxiosClient.put('/recovery_pin', { recovery: { phone, token }, user: { password, password_confirmation: passwordConfirmation } });
}

const AuthorizationClient = {
  signIn, signUp, requestPasswordReset, resetPassword, requestPasswordResetByPin, resetPasswordByPin
}

export default AuthorizationClient;
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Paper, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StepperStatus from "./StepperStatus";
import LoadingButton from "./LoadingButton";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "700px",
  },
  infoContainer: {
    height: "400px",
  },
  title: {
    fontWeight: "bold",
    fontSize: "25px",
    marginTop: "45px",
  },
  subtitle: {
    fontSize: "18px",
  },
  containerButton: {
    marginTop: "80px",
  },
  infoButtom: {
    height: 50,
    width: "200px",
    backgroundColor: "#239CC4 !important",
    marginTop: "30px",
    marginBottom: "20px",
    borderRadius: "10px",
    opacity: 1,
    "& span": {
      color: "#fff",
      fontSize: 18,
      textTransform: "none",
    },
  },
}));

export default function OrderInformation({ data }) {
  const classes = useStyles();
  const history = useHistory();
  const integrationStatus = data?.segsale_integration?.status;
  const [stepIndex, setStepIndex] = useState(undefined);
  const [textInfo, setTextInfo] = useState("");
  const [schedulingDate, setScheduleDate] = useState(undefined);
  const [inspectionCenter, setInpectionCenter] = useState(undefined);

  useEffect(() => {
    if (integrationStatus == ("CRIADO" || "PENDENTE")) {
      setStepIndex(1);
      setTextInfo(
        "Estamos confirmando o agendamento da sua inspeção veícular. Te avisaremos por email sobre esssa confirmação."
      );
    } else if (integrationStatus == "FATURADO") {
      setStepIndex(2);
      setTextInfo("Sua inspeção veícular foi agendada com sucesso!");
      setScheduleDate(
        formatDate(data?.schedule_confirmation?.presential_scheduling_datetime)
      );
      setInpectionCenter(data?.schedule_confirmation.inspection_center_name);
    }
  }, [data]);

  // Fomart date to dd/mm/yyyy
  function formatDate(date) {
    let dateFormated = new Date(date).toLocaleString("pt-br", {
      timeZone: "UTC",
    });
    return dateFormated.replace(/(.*)\D\d+/, "$1");
  }

  return (
    <div className={classes.root}>
      <Paper style={{ padding: 20 }} elevation={3}>
        <Grid container className={classes.infoContainer}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.title}>
              Status do pedido
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StepperStatus stepIndex={stepIndex} />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.subtitle}>{textInfo}</Typography>
          </Grid>
          {integrationStatus == "FATURADO" && (
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.subtitle}>
                  Local: {inspectionCenter}
                </Typography>
              </Grid>
              <br />
              <Grid item xs={12}>
                <Typography className={classes.subtitle}>
                  Data: {schedulingDate}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <LoadingButton
              onClick={() => history.push("/orders")}
              className={classes.infoButtom}
            >
              Meus pedidos
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../components/Header";
import Footer from "../components/Footer";
// import ReportPreview from "../components/ReportPreview";
import Report from "../components/Report";
import RegisterForm from "../components/RegisterForm";
import LoginForm from "../components/LoginForm";
import ModalForm from "../components/ModalForm";
import LoadingButton from "../components/LoadingButton";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    height: 50,
    backgroundColor: "#239CC4",
    marginTop: "10px",
    marginBottom: "50px",
    borderRadius: "10px",
    opacity: 1,
    "& span": {
      color: "#fff",
      fontSize: 18,
      textTransform: "none",
    },
  },
  paymentButton: {
    height: 50,
    backgroundColor: "#239CC4",
    margin: "10px 0px 10px 0px",
    borderRadius: "10px",
    opacity: 1,
    "& span": {
      color: "#fff",
      fontSize: 18,
      textTransform: "none",
    },
  },
}));

export default function VehicleInspection(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  // Attributes
  const occultReport = location?.state?.occultReport || undefined;
  const [serviceToPay, setServiceToPay] = useState(undefined);

  // Controll form login/register
  const [open, setOpen] = useState(false);
  // Definition of the modal component
  const [formSection, setFormSection] = React.useState(
    formSection || "register"
  );

  useEffect(() => {
    setOpen(props.currentUser == null);
  }, [props.currentUser]);

  function jumpToLogin() {
    setFormSection("login");
  }

  function jumpToRegister() {
    setFormSection("register");
  }

  // Go to payment
  function goToPayments() {
    history.push({
      pathname: "/payment",
      state: {
        reportContent: occultReport,
        serviceToPay: serviceToPay,
        currentUser: props.currentUser,
      },
    });
  }

  // Capture vehicle inspection value
  useEffect(() => {
    axios
      .get(`/api/v1/vehicle_inspection/inspection_price`)
      .then((res) => {
        let service = res?.data?.response.service_price;
        setServiceToPay(service);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div
      className={classes.root}
      style={{ filter: open ? "blur(10px)" : "none" }}
    >
      <Header
        currentUser={props.currentUser}
        setCurrentUser={props.currentUser}
      />
      <Report
        currentUser={props.currentUser}
        reportContent={occultReport}
        documentTitle="Laudo de vistoria cautelar"
      />
      <ModalForm
        currentUser={props.currentUser}
        setCurrentUser={props.setCurrentUser}
        open={open}
      >
        {formSection == "register" ? (
          <RegisterForm
            jumpToLogin={jumpToLogin}
            currentUser={props.currentUser}
            setCurrentUser={props.setCurrentUser}
          />
        ) : (
          <LoginForm
            jumpToRegister={jumpToRegister}
            currentUser={props.currentUser}
            setCurrentUser={props.setCurrentUser}
          />
        )}
      </ModalForm>
      <LoadingButton
        variant="contained"
        className={classes.paymentButton}
        onClick={goToPayments}
      >
        Prosseguir para pagamento
      </LoadingButton>
      <Footer />
    </div>
  );
}

import React from 'react';
import { 
  FormControl,
  Select,
  TextField,
  Typography,
  MenuItem,
  Grid,
  Link,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { TextMaskDocument, TextMaskLicensePlate } from '../components/TextMaskCustom.js'
import ornamentOne from 'images/enfeite1_icon.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('xl')]: {
      width: '1000px',
    },
    [theme.breakpoints.down('lg')]: {
      width: '900px',
    },
    [theme.breakpoints.down('md')]: {
      width: '850px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '570px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '300px'
    },
  },
  modalInfo:{
    backgroundColor: '#3232B2',
    backgroundSize: '350px',
    textAlign: 'left',
    color: '#FFF',
    backgroundSize: '90%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url('${ornamentOne}')`,
    backgroundPosition: 'bottom',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      backgroundImage: 'unset'
    },
  },
  titleInfo:{
    fontSize: 30,
    letterSpacing: 0,
    fontFamily: 'Noyh',
    fontWeight: '900',
    opacity: 1,
    [theme.breakpoints.up('lg')]: {
      marginTop: '15px',
      marginLeft: '20px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '15px',
      marginLeft: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
  },
  subTitleInfo:{
    fontSize: 22,
    letterSpacing: 0,
    fontFamily: 'Noyh',
    fontWeight: 'normal',
    opacity: 1,
    [theme.breakpoints.up('md')]: {
      marginLeft: '20px',
      maxWidth: '300px',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: '10px',
    },
  },
  modalForm:{
    backgroundColor: '#F7F7F7',
    opacity: 1,
  },
  formTitle:{
    fontSize: '20pt',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    marginLeft: '16px',
    marginTop: '20px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '100px',
      marginBottom: '22px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '50px',
      marginTop: '15px',
      marginBottom: '22px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '50px',
      marginBottom: '30px',
    },
  },
  formInput:{
    backgroundColor: '#fff',
    marginBottom: '25px',
    [theme.breakpoints.up('lg')]: {
      width: '400px',
    },
    [theme.breakpoints.down('md')]: {
      width: '335px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '194px',
    },
  },
  formSelect:{
    backgroundColor: '#fff',
    [theme.breakpoints.up('lg')]: {
      width: '400px',
    },
    [theme.breakpoints.down('md')]: {
      width: '335px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '194px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '194px',
    },
  },
  formAction:{
    textAlign:'center',
    marginTop: '30px',
    marginBottom: '30px',
  },
  formButton:{
    fontWeight: 900,
    color: '#fff',
    backgroundColor: '#05CC98',
    '& a':{
      display: 'block',
      marginTop: '10px',
      marginBottom: '5px',
      color: '#767676',
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      letterSpacing: 0,
      opacity: 1
    }
  },
}
));

export default function ExtraSearchForm(props) {

  const vehicle = props.vehicle && (props && props.vehicle) || []
  const setVehicle = props.setVehicle
  const classes = useStyles();
  
  // Available states
  const states = [
            {"label":"Acre","value":"AC"},
            {"label":"Alagoas","value":"AL"},
            {"label":"Amap\u00e1","value":"AP"},
            {"label":"Amazonas","value":"AM"},
            {"label":"Bahia","value":"BA"},
            {"label":"Cear\u00e1","value":"CE"},
            {"label":"Distrito Federal","value":"DF"},
            {"label":"Esp\u00edrito Santo","value":"ES"},
            {"label":"Goi\u00e1s","value":"GO"},
            {"label":"Maranh\u00e3o","value":"MA"},
            {"label":"Mato Grosso","value":"MT"},
            {"label":"Mato Grosso do Sul","value":"MS"},
            {"label":"Minas Gerais","value":"MG"},
            {"label":"Paran\u00e1","value":"PR"},
            {"label":"Para\u00edba","value":"PB"},
            {"label":"Par\u00e1","value":"PA"},
            {"label":"Pernambuco","value":"PE"},
            {"label":"Piau\u00ed","value":"PI"},
            {"label":"Rio Grande do Norte","value":"RN"},
            {"label":"Rio Grande do Sul","value":"RS"},
            {"label":"Rio de Janeiro","value":"RJ"},
            {"label":"Rond\u00f4nia","value":"RO"},
            {"label":"Roraima","value":"RR"},
            {"label":"Santa Catarina","value":"SC"},
            {"label":"Sergipe","value":"SE"},
            {"label":"S\u00e3o Paulo","value":"SP"},
            {"label":"Tocantins","value":"TO"}
        ]

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid className={classes.modalInfo} xs={12} sm={5} item>
          <Typography className={classes.titleInfo}>Bem vindo!</Typography>
          <Typography className={classes.subTitleInfo}>Para continuar, preencha as informações</Typography>
        </Grid>
        <Grid className={classes.modalForm} xs={12} sm={7} item>
          <Grid container>   
            <Grid xs={10} sm={10} item>
              <Typography className={classes.formTitle}>Começar</Typography>
            </Grid>
            <Grid xs={2} sm={2} style={{marginTop: '10px'}} item>
              <Link href='/' style={{color: '#707070', opacity: 1 }}>
                <CloseRoundedIcon style={{fontSize: '30px'}}/>
              </Link>
            </Grid>
          </Grid>
          <form onSubmit={props.searchDebts} noValidate> 
          {[
            {name: 'plate', label: 'Placa', placeholder: 'Digite sua placa', InputProps: {inputComponent: TextMaskLicensePlate}, shrink: true, autoComplete: false, required: true},
            {name: 'document', label: 'Documento', placeholder:'CPF/CNPJ do títular', InputProps: {inputComponent: TextMaskDocument}, shrink: true,  autoComplete: false, autoFocus: true, required: true, }
          ].map((field) =>
            <TextField
              id={field.name}
              variant="outlined"
              required={field.required}
              key={field.name}
              name={field.name}
              label={field.label}
              value={vehicle[field.name]}
              autoComplete={field.name}
              placeholder={field.placeholder}
              autoFocus={field.autoFocus}
              error={props.errors[field.name]}
              className={classes.formInput}
              helperText={props.errors[field.name] ? props.errors[field.name].join(', ') : ''}
              InputProps={field.InputProps}
              onChange={(e) => setVehicle({...vehicle, [field.name]: e.target.value.toUpperCase()})}
              InputLabelProps={{ shrink: field.shrink }}
            />
          )}

          <FormControl variant="outlined" error={props.errors.state}>
            <Select
              value={props.vehicle.state}
              className={classes.formSelect}
              inputProps={{'aria-label': 'Without label'}}
              onChange={(e) => props.setVehicle({...props.vehicle, state: e.target.value})}
              displayEmpty
            >
              <MenuItem value="" disabled>Selecione o estado</MenuItem>
              {states.map((state) =>
                <MenuItem value={state.value}>{state.label}</MenuItem>
              )}
            </Select>
            {props.errors.state ? <div style={{textAlign: 'left', color: 'red', marginBottom: '5px' }}>{props.errors.state}</div> : null}
          </FormControl>
          <div className={classes.formAction}>
            <Button
              variant="contained"
              size="large"
              type="submit"
              className={classes.formButton}
              disableElevation
            >Enviar
            </Button>
          </div>
        </form>
      </Grid>
    </Grid>
  </div>
  )
}
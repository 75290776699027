import React, { useState } from "react";
import {
  TextField,
  Typography,
  Grid,
  Link,
  Hidden,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AuthorizationClient from "../api/AuthorizationClient.js";
import ornamentOne from "images/ornament_login.svg";
import sucessIcon from "images/sucess-icon.svg";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xl")]: {
      minWidth: "35%",
    },
    [theme.breakpoints.down("lg")]: {
      minWidth: "60%",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "90%",
    },
  },
  modalOrnament: {
    backgroundColor: "#012F47",
    backgroundImage: `url('${ornamentOne}')`,
    backgroundSize: "90%",
    backgroundRepeat: "no-repeat",
    "& h1": {
      color: "#FFF",
      textAlign: "left",
      fontFamily: "Noyh",
      fontWeight: "normal",
      fontSize: 30,
      letterSpacing: 0,
      opacity: 1,
      marginLeft: "20px",
      marginTop: "20px",
    },
  },
  modalForm: {
    backgroundColor: "#F7F7F7",
    opacity: 1,
    "& form": {
      margin: "0 40px",
    },
  },
  modalTitle: {
    fontSize: "20pt",
    fontFamily: "Poppins",
    fontWeight: "bold",
    marginLeft: "40px",
    marginTop: "10px",
  },
  modalInput: {
    backgroundColor: "#fff",
    marginTop: "15px",
    "& label, input": {
      fontFamily: "Noyh",
    },
  },
  linkPassword: {
    textAlign: "left",
    marginLeft: "15px",
    color: "#767676",
    fontSize: 14,
    fontFamily: "Open Sans",
    fontWeight: "bold",
    letterSpacing: 0,
    opacity: 1,
  },
  modalActions: {
    textAlign: "center",
    marginTop: "120px",
    "& button": {
      fontWeight: 900,
      color: "#fff",
      backgroundColor: "#00B26C",
    },
    "& a": {
      display: "block",
      marginTop: "10px",
      marginBottom: "5px",
      color: "#767676",
      fontSize: 14,
      fontFamily: "Open Sans",
      fontWeight: "bold",
      letterSpacing: 0,
      opacity: 1,
    },
  },
}));

export default function LoginForm(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();

  function login(e) {
    e.preventDefault();
    AuthorizationClient.signIn({
      email: email,
      password: password,
      seach_id: props.searchId,
    })
      .then((res) => {
        swal({
          title: "Login realizado com sucesso",
          icon: `${sucessIcon}`,
        });
        if (window.location.pathname == "/vehicle_inspection") {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          swal({
            title: "Login realizado com sucesso",
            icon: `${sucessIcon}`,
          });
          setTimeout(() => {
            window.location.href = "/orders";
          }, 2000);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          setEmailError("Email ou senha inválidos");
          setPasswordError("Email ou senha inválidos");
        } else {
          setEmailError(null);
        }
      });
  }

  // Alert display
  function customAlert(title, text, type) {
    swal(title, text, type);
  }

  return (
    <div className={classes.root}>
      <Grid sm={12} container>
        <Hidden xsDown>
          <Grid className={classes.modalOrnament} sm={5} item>
            {/* <Typography variant='h1'>Faça o login para continuar.</Typography> */}
          </Grid>
        </Hidden>

        <Grid className={classes.modalForm} sm={7} item>
          <Grid container>
            <Grid xs={10} item>
              <Typography align="left" className={classes.modalTitle}>
                Entrar
              </Typography>
            </Grid>
            <Grid xs={2} style={{ marginTop: "10px" }} item>
              <Link href="/" style={{ color: "#707070", opacity: 1 }}>
                <CloseRoundedIcon style={{ fontSize: "30px" }} />
              </Link>
            </Grid>
          </Grid>
          <form onSubmit={login} noValidate>
            <input
              type="hidden"
              name="user[search_id]"
              value={props.searchId}
            />
            <TextField
              id="email"
              value={email}
              error={emailError}
              helperText={emailError}
              className={classes.modalInput}
              name="email"
              label="E-mail"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />

            <TextField
              id="password"
              value={password}
              error={passwordError}
              helperText={passwordError}
              className={classes.modalInput}
              label="Senha"
              type="password"
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
            />
            <Link
              style={{ display: "block", textAlign: "left", marginTop: "10px" }}
              href="/request_password_reset"
              className={classes.linkPassword}
            >
              Não lembra da senha?
            </Link>
            <div className={classes.modalActions}>
              <Button
                variant="contained"
                size="large"
                type="submit"
                disableElevation
              >
                Enviar
              </Button>
              <Link
                onClick={props.jumpToRegister}
                style={{ margin: "15px 0px" }}
              >
                Não é membro?{" "}
                <span style={{ color: "#012F47" }}>Cadastre-se</span>
              </Link>
            </div>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}

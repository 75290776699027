import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { 
  Grid,
  Typography,
  Paper,
  Divider,
} from '@material-ui/core';
import invoiceTip from 'images/invoice_tip.svg'

const useStyles = makeStyles((theme) => ({
  root:{
    
  },
  invoice:{   
    backgroundColor: '#fff',
    width: '275px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    position: 'relative',
    marginTop: '30px',
    marginLeft: '20px',
    marginRight: '20px',
    '& h1':{
      marginTop: '10px',
      textAlign: 'center',
      fontSize: 22,
    },
    '& h6':{
      fontSize: 26,
      fontWeight: 900,
    },
    "&::before": {
      content: `''`,
      display: 'block',
      position: 'absolute',
      height: '17px',
      width: '100%',
      marginTop: '-16px',
      backgroundImage: `url('${invoiceTip}')`,
      backgroundRepeat: 'no-repeat',
    },
    "&::after": {
      content: `''`,
      display: 'block',
      position: 'absolute',
      height: '17px',
      width: '100%',
      marginTop: '-2px',
      backgroundImage: `url('${invoiceTip}')`,
      backgroundRepeat: 'no-repeat',
      transform: 'rotate(180deg)',
    },
  },
  licensePlate:{
    position: 'relative',
    backgroundColor: '#e4eaf9',
    borderRadius: '12px',
    borderTop: '15px solid #45e2b1',
    textAlign: 'center',
    maxWidth: '160px',
    margin: '0 auto',
  },
  selectedDebits:{
    padding: '15px'
  },
  amountValueDebtits:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

export default function OrderDetails(props){
  const classes = useStyles();
  // Attributes
  const currentLicensePlate = props?.currentLicensePlate || [];
  const serviceToPay = props?.serviceToPay || [];
  const totalToPay = props?.totalToPay || [];

  // // BRL currency formatting
  function formatCurrency(value) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
  }

  return (
      <div className={classes.root}>
        <Grid container>
          <div className={classes.invoice}>
            <Typography variant="h1">Resumo</Typography>
            <div>
              <Typography className={classes.licensePlate} variant="subtitle1">
                { currentLicensePlate.length > 0 ? `${currentLicensePlate.substring(0, 3)}-${currentLicensePlate.substring(3, 7)}` : 'AAA-0000' }
              </Typography>
            </div>
            <div className={classes.selectedDebits}>
              <div style={{marginTop: '8px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px'}}>
                  <Typography style={{opacity: 0.5, fontSize: '12px', fontFamily: 'Source Sans Pro', marginRight: '10px'}}>{serviceToPay.product}</Typography>
                  <Typography style={{color: '#333333', fontSize: '14px', fontFamily: 'Source Sans Pro', fontWeight: 600}}>{formatCurrency(serviceToPay.price)}</Typography>
                </div>
                <Divider style={{color: '#000'}}/>
              </div>
              <br/>
              { totalToPay > 0 &&
                <div className={classes.amountValueDebtits}>
                  <Typography size='16' style={{opacity: 0.5, color: '#333333'}}>Total</Typography>
                  <Typography size='32' style={{color: '#333333', fontFamily: 'Source Sans Pro', fontWeight: 900}}>
                    { formatCurrency(totalToPay) }
                  </Typography>
                </div>
              }
            </div>
          </div>
        </Grid>
      </div>
    )
  }
import React from 'react'
import { makeStyles} from '@material-ui/core/styles';
import { 
  Grid,
  Box,
} from '@material-ui/core'
import Header from '../components/Header'
import ornamentOne from 'images/enfeite1_icon.svg'
import ornamentalIcons from 'images/ornamental-icons.svg'
import OrderDetails from '../components/OrderDetails'
import Footer from '../components/Footer'

const useStyles = makeStyles((theme) => ({
  root: {
   
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  ornament:{
    backgroundColor: '#0C1364',
    backgroundImage: `url('${ornamentOne}')`,
    backgroundRepeat: 'repeat-x',
    backgroundSize: '95px',
    height: '70px',
  },
}
));

export default function CheckoutLayout(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header currentUser={props.currentUser}/>
      <Grid sm={12} style={{margin: '20px'}}  container>
        {/* Side ornament bar  */}
        {props.debtsPending && props.debtAmount && props.currentVehicle && !props.paymentSuccess ? 
        (
        <Grid sm={3} item>
          <OrderDetails 
            debtsPending={props.debtsPending}
            debtAmount={props.debtAmount} 
            currentVehicle={props.currentVehicle}
          />
        </Grid>
        )
        :
        (
        <Grid sm={3} item>
        </Grid>
        )
        }
        {/* Forms bar */}
        <Grid sm={6} item>
          <Box boxShadow={3}>
            {props.children}
          </Box>
        </Grid>
      </Grid>
      <div className={classes.ornament}/>
      <Footer/>
    </div>
  )
}
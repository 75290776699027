import React, { useState } from 'react';
import { 
  Avatar,
  Container,
  Button,
  TextField, 
  FormControlLabel,
  Checkbox, 
  Typography,
  Grid
} from '@material-ui/core';

import { Link, useHistory} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { toast } from 'react-toastify';
import AuthorizationClient from '../../single-page/api/AuthorizationClient'
import LoadingButton from '../../components/LoadingButton.js'

import Header from '../components/Header'
import Footer from '../components/Footer';
import swal from 'sweetalert'
import sucessIcon from 'images/sucess-icon.svg'
import errorIcon from 'images/error-icon.svg'


const rdmcolor = '#D12E5E'

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: rdmcolor,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    backgroundColor: '#239CC4',
    margin: theme.spacing(3, 0, 2),
  },
  modalInput:{
    backgroundColor: '#fff',
    marginTop: '15px',
  }
}));

export default function RequestPasswordReset() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  function requestPasswordReset(e) {
    e.preventDefault();
    document.activeElement.blur();
    setLoading(true);
      AuthorizationClient.requestPasswordReset({ email: email })
      .then(function(res){
        swal({
          title: 'Olhe sua caixa de e-mail para concluir o processo!',
          icon: `${sucessIcon}`,
        }).then(() => {
          window.location.href = '/'
        });
      })
      .catch(function(response){
        if (response.status == 422){
          setEmailError(response.data.errors.email.join(', '));
        } else {
          setEmailError();
        }
        if (response.data.error) {
          swal({
            title: `Erro: ${response.data.error}`,
            icon: `${errorIcon}`,
          })
        } else {
          swal({
            title: 'Erro ao processar solicitação',
            icon: `${errorIcon}`,
          })
        }
      })
      .then(function() {
        setLoading(false);
      });
  }

  return (
    <div>
      <Header/>
      <Container maxWidth="sm" style={{marginTop: '50px', marginBottom: '200px', minHeight: 300}}>
      {/* <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar> */}
      <Typography component="h1" variant="h5">
        Recuperar minha senha
      </Typography>
      <form className={classes.form} onSubmit={requestPasswordReset} noValidate>
        <TextField
          required
          id="email"
          label="Email"
          name="email"
          variant="outlined"
          fullWidth={true}
          value={email}
          error={emailError}
          helperText={emailError}
          onChange={(e) => setEmail(e.target.value)}
          className={classes.modalInput}
          autoComplete='email'
          autoFocus
        />

        <LoadingButton
          type="submit"
          fullWidth
          loading={loading}
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Recuperar minha senha
        </LoadingButton>
      </form>
      {/* <Grid container>
        <Grid item xs>
          <Link variant="body2">
            Entrar
          </Link>
        </Grid>
        <Grid item>
          <Link variant="body2">
            Não possui uma conta? Cadastre-se
          </Link>
        </Grid>
      </Grid> */}
      </Container>
      <Footer/>
    </div>
  );
}
import * as React from "react";

function SvgComponent6(props) {
  return (
    <svg
      data-name="Component 34 \u2013 2"
      xmlns="http://www.w3.org/2000/svg"
      width={150.318}
      height={193.648}
      transform="scale(0.4)"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={0.847}
          y1={0.646}
          x2={0.325}
          y2={0.247}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#00b26c" />
          <stop offset={1} stopColor="#239cc4" />
        </linearGradient>
      </defs>
      <path
        data-name="Path 2370"
        d="M4408.083 8732.133l-38.52-81.4h-13.138l46.3 97.848 10.738-.009 91.415-194.02h-13.127z"
        transform="translate(-4356.426 -8554.55)"
        fill="url(#prefix__a)"
      />
    </svg>
  );
}

export default SvgComponent6;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextMaskZipCode } from "../components/TextMaskCustom"; // Zip code mask
import { Grid, Typography, TextField } from "@material-ui/core";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "20px",
    marginTop: "20px",
    fontSize: 30,
    fontFamily: "Noyh",
    fontWeight: 900,
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
    },
  },
  textField: {
    "& label.Mui-focused": {
      color: "#536777",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset, &:hover fieldset": {
        borderColor: "#05CC98",
        opacity: 0.24,
      },
    },
    [theme.breakpoints.down("xs")]: {
      // width: "250px",
    },
  },
}));

export default function BillingAddressForm(props) {
  // Use of styles
  const classes = useStyles();
  const [mustSearchAddress, setMustSearchAddress] = React.useState(false)
  // Address search by zip code
  function getAddress() {
    if (!mustSearchAddress)
      return;
    axios
      .get(`https://viacep.com.br/ws/${props.address.zipCode}/json/`)
      .then((res) => {
        setMustSearchAddress(false);
        if (res.data.erro) return null;
        const {
          logradouro: street,
          localidade: city,
          bairro: neighborhood,
          uf: state,
        } = res.data;
        props.setAddress({
          ...props?.address,
          street,
          city,
          neighborhood,
          state,
          number: "",
          complement: "",
        });
      });
  }

  function handleCepChange(e) {
    setMustSearchAddress(true);
    props.setAddress({ zipCode: e.target.value })
  }

  return (
    <Grid spacing={2} container>
      <Grid xs={12} item>
        <TextField
          id="address-cep"
          label="CEP"
          variant="outlined"
          name="zip-code"
          className={classes.textField}
          onBlur={getAddress}
          value={props.address.zipCode}
          onChange={handleCepChange}
          error={props.errors.zip_code ? true : false}
          helperText={props.errors.zipCode}
          InputProps={{ inputComponent: TextMaskZipCode }}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>

      <Grid xs={12} item>
        <TextField
          id="address-street"
          label="Endereço Atual"
          variant="outlined"
          autoComplete="off"
          className={classes.textField}
          error={props.errors.address ? true : false}
          helperText={props.errors.address}
          onChange={(e) =>
            props.setAddress({ ...props.address, street: e.target.value })
          }
          value={props.address.street}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>

      <Grid xs={12} sm={4} item>
        <TextField
          id="address-number"
          label="Número"
          variant="outlined"
          type="tel"
          value={props.address.number}
          error={props.errors.number ? true : false}
          helperText={props.errors.number}
          className={classes.textField}
          onChange={(e) =>
            props.setAddress({ ...props.address, number: e.target.value })
          }
          autocomplete="off"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>

      <Grid xs={12} sm={8} item>
        <TextField
          id="address-complement"
          label="Complemento"
          variant="outlined"
          className={classes.textField}
          value={props.address.complement}
          onChange={(e) =>
            props.setAddress({ ...props.address, complement: e.target.value })
          }
          autocomplete="off"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>

      <Grid xs={12} sm={6} item>
        <TextField
          id="address-neighborhood"
          label="Bairro"
          variant="outlined"
          className={classes.textField}
          error={props.errors.neighborhood ? true : false}
          helperText={props.errors.neighborhood}
          onChange={(e) =>
            props.setAddress({
              ...props.address,
              neighborhood: e.target.value,
            })
          }
          value={props.address.neighborhood}
          autocomplete="off"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>

      <Grid xs={12} sm={6} item>
        <TextField
          id="address-city"
          label="Cidade"
          variant="outlined"
          className={classes.textField}
          error={props.errors.city ? true : false}
          helperText={props.errors.city}
          onChange={(e) =>
            props.setAddress({ ...props.address, city: e.target.value })
          }
          value={props.address.city}
          autocomplete="off"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>

      <Grid xs={12} item>
        <TextField
          id="address-state"
          label="Estado"
          variant="outlined"
          className={classes.textField}
          error={props.errors.state ? true : false}
          helperText={props.errors.state}
          onChange={(e) =>
            props.setAddress({ ...props.address, state: e.target.value })
          }
          value={props.address.state}
          autocomplete="off"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

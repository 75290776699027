import React, { useState} from 'react';
import { 
  TextField, 
  Typography,
  Grid,
  Link,
  Button,
  Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import swal from 'sweetalert'
import sucessIcon from 'images/sucess-icon.svg'
import errorIcon from 'images/error-icon.svg'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ornamentOne from 'images/enfeite1_icon.svg'
import {TextMaskDocument, TextMaskPhone } from '../components/TextMaskCustom.js'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: '35%',
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '60%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%'
    },
  },
  decorativeModal:{
    backgroundColor: '#012F47',
    backgroundSize: '90%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url('${ornamentOne}')`,
    backgroundPosition: 'bottom',
  },
  titleDecorativeModal:{
    color: '#FFF',
    textAlign: 'left',
    fontFamily: 'Noyh',
    fontWeight: 'normal',
    fontSize: 30,
    letterSpacing: 0,
    opacity: 1,
    marginLeft: '20px',
    marginTop: '20px'
  },
  formModal:{
    backgroundColor: '#F7F7F7',
  },
  form:{
    margin: '0 40px',
  },
  titleModalForm:{
    fontSize: '15pt',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    marginLeft: '40px',
    marginTop: '10px',
  },
  formInput:{
    marginTop: '15px',
    '& label, input':{
      fontFamily: 'Noyh',
    }
  },
  modalActions:{
    display: 'flex',
    marginTop: '20px',
    marginBottom: '20px',
    backgroundColor: '#239cc4',
    fontWeight: 900,
    color: '#fff',
    '& a':{
      color: '#000',
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      letterSpacing: 0,
      opacity: 1
    }
  },
}
));

export default function SupportForm() {
  const classes = useStyles();

  const [support, setSupport] = useState({
    name: '',
    email: '',
    document: '',
    phone: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  function send_support(e) {
    e.preventDefault();
    axios.post(`/api/v1/users/support`,
    {
      support:{
        name: support.name,
        email: support.email,
        document: support.document,
        phone: support.phone,
        message: support.message,
      }
    },
    ).then(res => {
      console.log("request sucess")
      swal({
        title: 'Envio realizado!',
        icon: `${sucessIcon}`,
      }).then(() => {
        window.location.href = '/'
      });
    }).catch(error => {
      console.log("request error")
      if (error.response.status == 422){
        setErrors(error.response.data.errors)
        swal({
          title: 'Verifique os campos!',
          text: 'Existem campos incorretos.',
          icon: `${errorIcon}`,
        })
      } else {
        swal({
          title: error.response.data.errors,
          icon: `${errorIcon}`,
        })
      }      
    })
  }

  return (
    <div className={classes.root}>

    <Grid container>

      <Hidden xsDown>
        <Grid className={classes.decorativeModal} xs={12} sm={5} item>
          <Typography  className={classes.titleDecorativeModal} variant='h1'>
            Como podemos lhe ajudar?
          </Typography>
        </Grid>
      </Hidden>
      
      <Grid className={classes.formModal} sm={7}>
        <Grid container>
          <Grid xs={10} item>
            <Typography align='left' className={classes.titleModalForm}>
              Fale conosco
            </Typography>
          </Grid>
          <Grid xs={2} style={{marginTop: '10px'}} item>
            <Link href='/' style={{color: '#707070', opacity: 1 }}>
              <CloseRoundedIcon style={{fontSize: '30px'}}/>
            </Link>
          </Grid>
        </Grid>

        <form className={classes.form} onSubmit={send_support} noValidate>
          {[
            { name: 'name', label: 'Nome', type: 'text', autoFocus: true, required: true },
            { name: 'email', label: 'Email', type: 'email', required: true },
            { name: 'document', label: 'Documento', InputProps: {inputComponent: TextMaskDocument}, type: 'text',   require: true},
            { name: 'phone', label: 'Telefone', type: 'text', InputProps: {inputComponent: TextMaskPhone},  require: true}
          ].map((field) =>
            <TextField
              required={field.required}
              multiline
              key={field.name}
              id={field.name}
              name={field.name}
              className={classes.formInput}
              variant='outlined'
              label={field.label}
              fullWidth={true}
              autoComplete={field.name}
              autoFocus={field.autoFocus}
              value={support[field.name]}
              error={errors[field.name] && true}
              type={field.type}
              InputProps={field.InputProps}
              helperText={errors[field.name] ? errors[field.name].join(', ') : ''}
              onChange={(e) => setSupport({...support, [field.name]: e.target.value})}
            />
          )}
          
          <TextField
            id="outlined-multiline-static"
            label="Descrição"
            name="message"
            multiline
            className={classes.formInput}
            rows={10}
            placeholder="Informe sua solicitação"
            variant="outlined"
            error={errors["message"] && true}
            onChange={(e) => setSupport({...support, message: e.target.value})}
            helperText={errors["message"] ? errors["message"].join(', ') : ''}
            fullWidth
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.modalActions}
          >
            Enviar
          </Button>

        </form>
      </Grid>
    </Grid>
  </div>
  )
}
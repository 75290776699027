import React, {useState} from 'react'
import { 
  TextField, 
  Typography,
  Grid,
  } from '@material-ui/core';
  import { makeStyles } from '@material-ui/core/styles';
  import CreditCard from '../components/CreditCard'
  // Credit card mask
  import {
  TextMaskCreditCard,
  TextMaskCreditExpiration,
  TextMaskCreditCardCVV } from '../components/TextMaskCustom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  boxCard:{
    maxWidth: '516px',
    marginBottom: '10px',
    margin: 'unset'
  },
  title:{
    textAlign: 'left',
    marginTop: '20px',
    marginBottom: '10px',
    fontFamily: 'Noyh',
    fontWeight: 900,
    fontSize: 30,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px',
    },
  },
  card:{
    marginBottom: '40px',
  },
  textField: {
    '& label.Mui-focused': {
      color: '#536777',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset, &:hover fieldset': {
        borderColor: '#05CC98',
        opacity: 0.24,
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: '250px',
    },
  },
}));

export default function CardForm(props){
  // Use of styles
  const classes = useStyles();

  // Method of handling credit card focus fields
  function handleCardInputFocus(e) {
    props.setCard({...props.card, focus: e.target.name });
  }

  // Method of handling credit card values
  function handleCardInputChange(e) {
    const { name, value } = e.target;
    props.setCard({...props.card, [name]: value });
  }
  
  return (
  <div className={classes.root}>
    <Grid container className={classes.boxCard} spacing={2}>
      <Grid xs={12} item>
        <Typography className={classes.title}>
          Dados do Cartão
        </Typography>
      </Grid>
      <Grid xs={12} item>
        {/* Credit card animation */}
        <div className={classes.card} >
          <CreditCard card={props.card}/>
        </div>
      </Grid>
      <Grid xs={12} item>
        <TextField
          id="card-number"
          name="number"
          label="Numero"
          variant="outlined"
          autoComplete="cc-number"
          type="tel"
          className={classes.textField}
          value={props.card.number}
          error={props.errors.card_holder_name ? true : false }
          helperText={props.errors.card_holder_name}
          onChange={handleCardInputChange}
          onFocus={handleCardInputFocus}
          InputProps={{ inputComponent: TextMaskCreditCard }}
          autocomplete="off"
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          id="card-name"
          label="Nome no cartão"
          name="name"
          variant="outlined"
          className={classes.textField}
          value={props.card.name}
          error={props.errors.card_number ? true : false }
          helperText={props.errors.card_number}
          onChange={handleCardInputChange}
          onFocus={handleCardInputFocus}
          autocomplete="off"
          fullWidth
        />
      </Grid>
      <Grid xs={12} sm={6} item>
        <TextField
          id="card-expiry"
          label="Validade"
          name="expiry"
          variant="outlined"
          className={classes.textField}
          value={props.card.expiry}
          error={props.errors.validate_card ? true : false }
          helperText={props.errors.validate_card}
          onChange={handleCardInputChange}
          onFocus={handleCardInputFocus}
          InputProps={{ inputComponent: TextMaskCreditExpiration }}
          autocomplete="off"
          fullWidth
        />
      </Grid>
      <Grid xs={12} sm={6} item>
        <TextField
          id="card-cvv"
          label="CVV"
          name="cvc"
          type="tel"
          variant="outlined"
          className={classes.textField}
          value={props.card.cvc}
          error={props.errors.cvv ? true : false }
          helperText={props.errors.cvv}
          onChange={handleCardInputChange}
          onFocus={handleCardInputFocus}
          InputProps={{inputComponent: TextMaskCreditCardCVV}}
          autocomplete="off"
          fullWidth
        />
      </Grid>
    </Grid>
  </div>
)}
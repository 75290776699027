import * as React from "react";
function SvgComponent3(props) {
  return (
    <svg
      data-name="Component 30 \u2013 2"
      xmlns="http://www.w3.org/2000/svg"
      width={150.318}
      height={193.648}
      transform="scale(0.4)"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={0.333}
          y1={0.262}
          x2={0.852}
          y2={0.636}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#00b26c" />
          <stop offset={1} stopColor="#239cc4" />
        </linearGradient>
      </defs>
      <path
        data-name="Path 2366"
        d="M3744.547 8740.823l-44.068-93.125h-3.406l46.085 97.39h2.784l92.134-195.546h-3.4z"
        transform="translate(-3697.074 -8549.54)"
        fill="url(#prefix__a)"
      />
    </svg>
  );
}

export default SvgComponent3;

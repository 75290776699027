import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Carousel, { autoplayPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { Typography, Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import personAvatar from "images/person.jpg";
import { styles } from "../../components/FileField";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "50px",
  },
  member: {
    color: "#FFFFFF",
    textAlign: "center",
    "& h1": {
      fontSize: 18,
      fontWeight: 900,
    },
    "& p": {
      maxWidth: "350px",
      fontWeight: "normal",
      opacity: 0.8,
    },
  },
}));

function GenericCarousel({ itens, itensPerPage = 1, showArrows = false }) {
  const classes = useStyles();

  return (
    <div className={classes.root} id="depositions">
      <Carousel
        slidesPerPage={itensPerPage}
        arrows={showArrows}
        autoPlay={1800}
        animationSpeed={1300}
        infinite
        breakpoints={{
          640: {
            slidesPerPage: 1,
          },
          900: {
            slidesPerPage: 1,
          },
        }}
      >
        {itens.map((item, i) => {
          return (
            <img
              key={i}
              src={item.imgUrl}
              alt={item.name}
              style={{ maxWidth: "180px" }}
            />
          );
        })}
      </Carousel>
    </div>
  );
}

export default GenericCarousel;

import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import AuthorizationClient from '../api/AuthorizationClient.js'
import { 
  TextField, 
  Typography,
  Grid,
  Link,
  Button,
  Hidden
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ornamentZero from 'images/ornament_register.svg'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import sucessIcon from 'images/sucess-icon.svg'
import {TextMaskName, TextMaskDocument, TextMaskPhone } from '../components/TextMaskCustom.js'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: '35%',
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '60%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%'
    },
  },
  modalOrnament:{
    backgroundColor: '#012F47',
    backgroundSize: '95%',
    backgroundImage: `url('${ornamentZero}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    '& h1':{
      color: '#FFF',
      textAlign: 'left',
      fontFamily: 'Noyh',
      fontWeight: 'normal',
      fontSize: 30,
      letterSpacing: 0,
      opacity: 1,
      marginLeft: '20px',
      marginTop: '20px'
    },
  },
  modalForm:{
    backgroundColor: '#F7F7F7',
    opacity: 1,
    '& form':{
      margin: '0 40px',
    },
  },
  modalTitle:{
    fontSize: '20pt',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    marginLeft: '40px',
    marginTop: '10px',
  },
  modalInput:{
    marginTop: '15px',
    '& label, input':{
      fontFamily: 'Noyh',
    }
  },
  modalActions:{
    textAlign:'center',
    '& button':{
      fontWeight: 900,
      color: '#fff',
      backgroundColor: '#239CC4'
    },
    '& a':{
      display: 'block',
      marginTop: '10px',
      marginBottom: '5px',
      color: '#767676',
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      letterSpacing: 0,
      opacity: 1
    }
  },
}
));

export default function RegisterForm(props) {
  const classes = useStyles();
  let history = useHistory();

  const [user, setUser] = useState({
    name: '',
    last_name: '',
    email: '',
    document: '',
    phone: '',
    password: '',
    password_confirmation: '',
    searchId: props.searchId
  })
  
  const [userErrors, setUserErrors] = useState({});

  let { from } =  { from: { pathname: "/debts" } };

  function register(e) {
    e.preventDefault()
    AuthorizationClient.signUp(user)
    .then(response => {
      swal({
        title: 'Cadastro realizado com sucesso',
        icon: `${sucessIcon}`,
      })
      if (window.location.pathname == '/vehicle_inspection' || window.location.pathname == '/'){
        window.location.reload();
      }else{
        window.location.href = '/orders';
      }
      props.setCurrentUser(response);
    })
    .catch(error => {
      if (error.status == 422 && error.data) {
        setUserErrors(error.data);
      } else {
        setUserErrors({});
        if (error.data && error.data.error) {
          // toast.error(`Erro: ${error.data.error}`);
        } else {
          // toast.error(`Erro ao processar solicitação.`);
        }
      }
    })
  }
  
  return (
    <div className={classes.root}>
      <Grid sm={12} container>
       
        <Hidden xsDown>
          <Grid className={classes.modalOrnament} sm={5} item>
            {/* <Typography variant='h1'>Conclua seu cadastro e visualize seus débitos.</Typography> */}
          </Grid>
        </Hidden>

        <Grid className={classes.modalForm} sm={7} item>
            <Grid container>
              <Grid xs={10} item>
                <Typography align='left' className={classes.modalTitle}>
                  Cadastro
                </Typography>
              </Grid>
              <Grid xs={2} style={{marginTop: '10px'}} item>
                <Link href='/' style={{color: '#707070', opacity: 1 }}>
                  <CloseRoundedIcon style={{fontSize: '30px'}}/>
                </Link>
              </Grid>
            </Grid>

          <br/>
          <form onSubmit={register} noValidate>

            <input type="hidden" name="user[search_id]" value={props.searchId}/>
            
            <TextField
                required
                key='name'
                id='name' 
                label='Nome' 
                value={user['name']}
                className={classes.modalInput}
                onChange={(e) => setUser({...user, name: e.target.value})}
                error={userErrors.name ? true : false }
                helperText={userErrors.name  ? userErrors.name .join(', ') : ''}
                InputProps={{ inputComponent: TextMaskName}}
                variant='outlined' 
                fullWidth/>
            
            <TextField
                required
                key='last_name'
                id='last_name' 
                label='Sobrenome'
                value={user['last_name']}
                className={classes.modalInput}
                onChange={(e) => setUser({...user, last_name: e.target.value})}
                error={userErrors.last_name ? true : false }
                helperText={userErrors.last_name  ? userErrors.last_name .join(', ') : ''}
                variant='outlined' 
                fullWidth/>
      
          <TextField 
              required
              id="email"
              label="E-mail"
              value={user['email']}
              className={classes.modalInput}
              onChange={(e) => setUser({...user, email: e.target.value})}
              error={userErrors.email ? true : false }
              helperText={userErrors.email   ? userErrors.email .join(', ') : ''}
              variant="outlined"
              fullWidth/>
         
            <TextField 
                required
                id="document"
                label="CPF ou CNPJ"
                value={user['document']}
                className={classes.modalInput}
                onChange={(e) => setUser({...user, document: e.target.value})}
                error={userErrors.document ? true : false }
                helperText={userErrors.document  ? userErrors.document .join(', ') : ''}
                InputProps={{ inputComponent: TextMaskDocument}}
                variant="outlined"
                fullWidth/>
          
            <TextField 
                required
                id="phone"
                label="Celular"
                value={user['phone']}
                className={classes.modalInput}
                onChange={(e) => setUser({...user, phone: e.target.value})}
                error={userErrors.phone ? true : false }
                helperText={userErrors.phone  ? userErrors.phone .join(', ') : ''}
                variant="outlined"
                InputProps={{ inputComponent: TextMaskPhone }}
                fullWidth/>
           
            <TextField
                required
                id="password"
                label="Senha"
                type="password"
                value={user['password']}
                className={classes.modalInput}
                onChange={(e) => setUser({...user, password: e.target.value})}
                error={userErrors.password ? true : false }
                helperText={userErrors.password  ? userErrors.password .join(', ') : ''}
                variant="outlined"
                fullWidth/>
   
            <TextField
                required
                id="password_confirmation"
                label="Confirme a senha"
                type="password"
                value={user['password_confirmation']}
                className={classes.modalInput}
                onChange={(e) => setUser({...user, password_confirmation: e.target.value})}
                error={userErrors.password_confirmation ? true : false }
                helperText={userErrors.password_confirmation  ? userErrors.password_confirmation .join(', ') : ''}
                variant="outlined"
                fullWidth/>

          <div className={classes.modalActions}>
           <br/><Button
              variant="contained"
              size="large"
              type="submit"
              disableElevation
            >Enviar
            </Button>
            <Link onClick={props.jumpToLogin} style={{margin: '15px 0px'}}>Já tem cadastro?<span style={{color: '#00B26C', cursor: 'pointer'}}> Entrar</span></Link>
          </div>
        </form>
      </Grid>
    </Grid>
  </div>
  )
}
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper, Divider } from "@material-ui/core";
import logoIcon from "images/marca_2_icon.svg";
import fakeQrCode from "images/fake_qr_code.svg";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "70%",
    margin: "50px auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
    marginBottom: "20px",
    justifyContent: "space-around",
  },
  reportLogo: {
    width: "40%",
  },
  reportHeaderTitle: {
    fontSize: "25px",
  },
  reportQrCode: {
    width: "20%",
    marginTop: "5px",
  },
  reportHeaderItens: {
    width: "50%",
  },
  report: {
    width: "100%",
    margin: "0 50px",
  },
  boxes: {
    display: "flex",
    justifyContent: "space-around",
  },
  box: {
    width: "100%",
    padding: "10px 15px",
  },
  boxTitle: {
    color: "#00B26C",
  },
  fields: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "23px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  subtitle: {
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  field: {
    fontSize: "17px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      paddingLeft: "20px",
    },
  },
}));

export default function Report({ documentTitle, reportContent }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {reportContent ? (
        <Paper elevation={3}>
          <Grid className={classes.container} container>
            {/* Report Header */}
            <Grid item xs={12} className={classes.header}>
              {/* <div className={classes.reportHeaderItens}>
              <img src={logoIcon} className={classes.reportLogo}/>
            </div> */}
              <div>
                <Typography className={classes.title}>
                  {documentTitle}
                </Typography>
              </div>
              {/* <div className={classes.reportHeaderItens}>
              <Typography noWrap>
                {reportContent.inspection_id.value}
              </Typography>
            </div> */}
            </Grid>

            <Grid item className={classes.report} xs={12}>
              {/* Vehicle */}
              <Grid container className={classes.boxes} xs={12}>
                <Grid className={classes.boxTitle} item xs={12}>
                  <h1 className={classes.title}>Veículo</h1>
                </Grid>
                <Grid item className={classes.box} md={6}>
                  <Grid item xs={12} className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.license_plate.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.license_plate.value}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.billed_document.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.billed_document.value}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.billing_state.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.billing_state.value}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.bodywork_type.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.bodywork_type.value}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item className={classes.box} md={6}>
                  <Grid item xs={12} className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.brand.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.brand.value}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.capacity_weight_in_kg.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.capacity_weight_in_kg.value}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.category.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.category.value}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.chassis_rescheduling.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.chassis_rescheduling.value}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container className={classes.boxes} xs={12}>
                <Grid item className={classes.box} md={6}>
                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.chassis_situation.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.chassis_situation.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.city.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.city.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.color.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.color.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.di_register_number.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.di_register_number.value}
                    </Typography>
                  </div>
                </Grid>

                <Grid item className={classes.box} md={6}>
                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.engine_number.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.engine_number.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.engine_power.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.engine_power.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.gearbox_number.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.gearbox_number.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.gross_weight_in_kg.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.gross_weight_in_kg.value}
                    </Typography>
                  </div>
                </Grid>
              </Grid>

              <Grid container className={classes.boxes} xs={12}>
                <Grid item className={classes.box} md={6}>
                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.last_update_date.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.last_update_date.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.maximum_tractive_force.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.maximum_tractive_force.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.model.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.model.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.model_year.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.model_year.value}
                    </Typography>
                  </div>
                </Grid>

                <Grid item className={classes.box} md={6}>
                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.number_axles.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.number_axles.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.number_of_passengers.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.number_of_passengers.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.origin.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.origin.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.potency.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.potency.value}
                    </Typography>
                  </div>
                </Grid>
              </Grid>

              <Grid container className={classes.boxes} xs={12}>
                <Grid item className={classes.box} md={6}>
                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.registration_chassis_number.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.registration_chassis_number.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.renavam.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.renavam.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.situation.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.situation.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.state.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.state.value}
                    </Typography>
                  </div>
                </Grid>

                <Grid item className={classes.box} md={6}>
                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.type_fuel.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.type_fuel.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.vehicle.vehicle_type.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.vehicle.vehicle_type.value}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Divider />

              {/* Renajud */}
              <Grid container className={classes.boxes} xs={12}>
                <Grid className={classes.boxTitle} item xs={12}>
                  <h1 className={classes.title}>Renajud</h1>
                </Grid>
                <Grid item className={classes.box} md={6}>
                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {
                        reportContent.renajud_registrations.renajud_data_limit
                          .name
                      }
                    </Typography>
                    <Typography className={classes.field}>
                      {
                        reportContent.renajud_registrations.renajud_data_limit
                          .value
                      }
                    </Typography>
                  </div>
                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.renajud_registrations.cmt.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.renajud_registrations.cmt.value}
                    </Typography>
                  </div>
                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {
                        reportContent.renajud_registrations.renajud_data_limit
                          .name
                      }
                    </Typography>
                    <Typography className={classes.field}>
                      {
                        reportContent.renajud_registrations.renajud_data_limit
                          .value
                      }
                    </Typography>
                  </div>
                </Grid>

                <Grid item className={classes.box} md={6}>
                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.renajud_registrations.renajud_montage.name}
                    </Typography>
                    <Typography className={classes.field} Typography>
                      {
                        reportContent.renajud_registrations.renajud_montage
                          .value
                      }
                    </Typography>
                  </div>
                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.renajud_registrations.ptb.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.renajud_registrations.ptb.value}
                    </Typography>
                  </div>
                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {
                        reportContent.renajud_registrations
                          .renajud_chassis_rescheduling.name
                      }
                    </Typography>
                    <Typography className={classes.field}>
                      {
                        reportContent.renajud_registrations
                          .renajud_chassis_rescheduling.value
                      }
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Divider />

              {/* Chassis Decoding */}
              <Grid container className={classes.boxes} xs={12}>
                <Grid className={classes.boxTitle} item xs={12}>
                  <h1 className={classes.title}>Decodificação de Chassi</h1>
                </Grid>
                <Grid item className={classes.box} md={6}>
                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {
                        reportContent.chassis_decoding.chassis_decoding_doors
                          .name
                      }
                    </Typography>
                    <Typography className={classes.field}>
                      {
                        reportContent.chassis_decoding.chassis_decoding_doors
                          .value
                      }
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {
                        reportContent.chassis_decoding.chassis_decoding_engine
                          .name
                      }
                    </Typography>
                    <Typography className={classes.field}>
                      {
                        reportContent.chassis_decoding.chassis_decoding_engine
                          .value
                      }
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {
                        reportContent.chassis_decoding.chassis_decoding_serial
                          .name
                      }
                    </Typography>
                    <Typography className={classes.field}>
                      {
                        reportContent.chassis_decoding.chassis_decoding_serial
                          .value
                      }
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {
                        reportContent.chassis_decoding.chassis_decoding_traction
                          .name
                      }
                    </Typography>
                    <Typography className={classes.field}>
                      {
                        reportContent.chassis_decoding.chassis_decoding_traction
                          .value
                      }
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {
                        reportContent.chassis_decoding.chassis_decoding_version
                          .name
                      }
                    </Typography>
                    <Typography className={classes.field}>
                      {
                        reportContent.chassis_decoding.chassis_decoding_version
                          .value
                      }
                    </Typography>
                  </div>
                </Grid>

                <Grid item className={classes.box} md={6}>
                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.chassis_decoding.conclusion.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.chassis_decoding.conclusion.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.chassis_decoding.manufacturer.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.chassis_decoding.manufacturer.value}
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {
                        reportContent.chassis_decoding.manufacturer_location
                          .name
                      }
                    </Typography>
                    <Typography className={classes.field}>
                      {
                        reportContent.chassis_decoding.manufacturer_location
                          .value
                      }
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {
                        reportContent.chassis_decoding.number_irregularities
                          .name
                      }
                    </Typography>
                    <Typography className={classes.field}>
                      {
                        reportContent.chassis_decoding.number_irregularities
                          .value
                      }
                    </Typography>
                  </div>

                  <div className={classes.fields}>
                    <Typography className={classes.field} noWrap>
                      {reportContent.chassis_decoding.region.name}
                    </Typography>
                    <Typography className={classes.field}>
                      {reportContent.chassis_decoding.region.value}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Divider />

              <Grid
                container
                className={classes.boxes}
                justifyContent="left"
                xs={12}
              >
                <Grid item className={classes.box} md={6}>
                  <Grid className={classes.boxTitle} item xs={12}>
                    <h1 className={classes.title}>
                      {reportContent?.show_fields
                        ? reportContent?.debts.length > 0
                          ? "Débitos"
                          : "Não há registro de débitos."
                        : "**********"}
                    </h1>
                  </Grid>
                  {/* Debts */}
                  {reportContent.debts.map((debt) => (
                    <div>
                      <div className={classes.fields}>
                        <Typography className={classes.field} noWrap>
                          {debt.debt_situation.name}
                        </Typography>
                        <Typography className={classes.field}>
                          {debt.debt_situation.value}
                        </Typography>
                      </div>

                      <div className={classes.fields}>
                        <Typography className={classes.field} noWrap>
                          {debt.debt_value.name}
                        </Typography>
                        <Typography className={classes.field}>
                          {debt.debt_value.value}
                        </Typography>
                      </div>

                      <div className={classes.fields}>
                        <Typography className={classes.field} noWrap>
                          {debt.register_type.name}
                        </Typography>
                        <Typography className={classes.field}>
                          {debt.register_type.value}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </Grid>

                <Grid item className={classes.box} md={6}>
                  <Grid className={classes.boxTitle} item xs={12}>
                    <h1 className={classes.title}>
                      {reportContent?.show_fields
                        ? reportContent?.thefts.length > 0
                          ? "Roubos"
                          : "Não há registros de roubos."
                        : "**********"}
                    </h1>
                  </Grid>
                  {/* Thefts */}
                  {reportContent.thefts.map((theft) => (
                    <div>
                      <div className={classes.fields}>
                        <Typography className={classes.field} noWrap>
                          {theft.declaration_year.name}
                        </Typography>
                        <Typography className={classes.field}>
                          {theft.declaration_year.value}
                        </Typography>
                      </div>
                      <div className={classes.fields}>
                        <Typography className={classes.field} noWrap>
                          {theft.bulletin.name}
                        </Typography>
                        <Typography className={classes.field}>
                          {theft.bulletin.value}
                        </Typography>
                      </div>
                      <div className={classes.fields}>
                        <Typography className={classes.field} noWrap>
                          {theft.occurrence_category.name}
                        </Typography>
                        <Typography className={classes.field}>
                          {theft.occurrence_category.value}
                        </Typography>
                      </div>
                      <div className={classes.fields}>
                        <Typography className={classes.field} noWrap>
                          {theft.date_occurrence.name}
                        </Typography>
                        <Typography className={classes.field}>
                          {theft.date_occurrence.value}
                        </Typography>
                      </div>
                      <div className={classes.fields}>
                        <Typography className={classes.field} noWrap>
                          {theft.city.name}
                        </Typography>
                        <Typography className={classes.field}>
                          {theft.city.value}
                        </Typography>
                      </div>
                      <div className={classes.fields}>
                        <Typography className={classes.field} noWrap>
                          {theft.occurrence_number.name}
                        </Typography>
                        <Typography className={classes.field}>
                          {theft.occurrence_number.value}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </Grid>
              </Grid>
              <Divider />

              {/* Owners */}
              <Grid
                container
                className={classes.boxes}
                justifyContent="left"
                xs={12}
              >
                <Grid item className={classes.box} md={6}>
                  <Grid className={classes.boxTitle} item xs={12}>
                    <h1 className={classes.title}>
                      {reportContent?.show_fields
                        ? reportContent?.owners.length > 0
                          ? "Proprietários"
                          : "Não há registros de proprietários."
                        : "**********"}
                    </h1>
                  </Grid>
                  {reportContent.owners.map((owner) => (
                    <div>
                      <div className={classes.fields}>
                        <Typography className={classes.field} noWrap>
                          {owner.document.name}
                        </Typography>
                        <Typography className={classes.field}>
                          {owner.document.value}
                        </Typography>
                      </div>

                      <div className={classes.fields}>
                        <Typography className={classes.field} noWrap>
                          {owner.name.name}
                        </Typography>
                        <Typography className={classes.field}>
                          {owner.name.value}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </Grid>

                <Grid item className={classes.box} md={6}>
                  <Grid className={classes.boxTitle} item xs={12}>
                    <h1 className={classes.title}>
                      {reportContent?.show_fields
                        ? reportContent?.restrictions.length > 0
                          ? "Restrições"
                          : "Não há registros de restrições."
                        : "**********"}
                    </h1>
                  </Grid>
                  {/* Restrições */}
                  {reportContent.restrictions.map((restriction) => (
                    <div>
                      <div>
                        <Typography className={classes.field} noWrap>
                          {restriction.message.name}
                        </Typography>
                        <Typography className={classes.field}>
                          {restriction.message.value}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </Grid>
              </Grid>
              <Divider />

              <Grid
                container
                className={classes.boxes}
                style={{ justifyContent: "unset" }}
                xs={12}
              >
                <Grid item className={classes.box} md={6}>
                  <Grid className={classes.boxTitle} item xs={12}>
                    <h1 className={classes.title}>
                      {reportContent?.show_fields
                        ? reportContent?.auctions.length > 0
                          ? "Leilões"
                          : "Não há registros de leilões."
                        : "**********"}
                    </h1>
                  </Grid>
                  {reportContent.auctions.map((auction) => (
                    <div>
                      <div className={classes.fields}>
                        <Typography className={classes.field} noWrap>
                          {auction.auctioneer.name}
                        </Typography>
                        <Typography className={classes.field}>
                          {auction.auctioneer.value}
                        </Typography>
                      </div>

                      <div className={classes.fields}>
                        <Typography className={classes.field} noWrap>
                          {auction.courtyard.name}
                        </Typography>
                        <Typography className={classes.field}>
                          {auction.courtyard.value}
                        </Typography>
                      </div>

                      <div className={classes.fields}>
                        <Typography className={classes.field} noWrap>
                          {auction.lot.name}
                        </Typography>
                        <Typography className={classes.field}>
                          {auction.lot.value}
                        </Typography>
                      </div>

                      <div className={classes.fields}>
                        <Typography className={classes.field} noWrap>
                          {auction.date.name}
                        </Typography>
                        <Typography className={classes.field}>
                          {auction.date.value}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Skeleton height={700} animation="wave" />
      )}
    </div>
  );
}

import * as React from "react";

function SvgComponent4(props) {
  return (
    <svg
      data-name="Component 32 \u2013 2"
      xmlns="http://www.w3.org/2000/svg"
      width={150.318}
      height={193.648}
      transform="scale(0.4)"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={0.082}
          y1={0.824}
          x2={1.096}
          y2={0.071}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#00b26c" />
          <stop offset={1} stopColor="#239cc4" />
        </linearGradient>
      </defs>
      <path
        data-name="Path 2368"
        d="M4076.316 8736.478l-41.294-87.263h-8.272l46.194 97.618 6.761-.006 91.774-194.782h-8.265z"
        transform="translate(-4026.75 -8552.045)"
        fill="url(#prefix__a)"
      />
    </svg>
  );
}

export default SvgComponent4;

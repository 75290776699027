import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Typography,
  Checkbox,
  Grid
} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles'
import ornamentTwo from 'images/enfeite2_icon.svg'
import Joyride from "react-joyride";
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const GreenCheckbox = withStyles({
  root: {
    color: 'default',
    '&$checked': {
      color: '#05CC98',
    },
  },
  checked: {},
})((props) => <Checkbox {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '425px',
    [theme.breakpoints.up('md')]: {
      margin: 'auto 80px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto 30px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 'auto 10px',
    },
  },
  debtListContainer:{
    backgroundColor: '#FFF',
    boxShadow: '16px 16px 48px #00000019',
  },
  rows:{
    border: '1px solid #F4F4F4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& h6':{
      color: '#333333',
      fontSize: '18px',
      lineHeight: '50px',
      fontFamily: 'Source Sans Pro',
      fontWeight: 'normal',
      opacity: '0.5'
    },
    '& p':{
      color: '#333333',
      fontSize: '18px',
      fontFamily: 'Source Sans Pro',
      fontWeight: 'normal',
      padding: '10px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      },
    },
  },
  title:{
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    letterSpacing: '0px',
    color: '#333333',
    opacity: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  debtsNotFound:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      height: '200px',
    },
    [theme.breakpoints.down('xs')]: {
     height: '150px',
    },
  },
  iconNotFound:{
    [theme.breakpoints.down('lg')]: {
      paddingRight: '25px',
     },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 'unset',
     },
  },
  titleNotFound:{
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    [theme.breakpoints.up('xl')]: {
      fontSize: '32px',
      paddingLeft: '35px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      paddingRight: '8px',
    },
  }
}));

export default function DebtList({activeDebits, paymentsChecked, setPaymentsChecked, currentUser}) {
  const classes = useStyles();
  // Tour
  const steps = [
    { 
      placement: 'bottom',
      target: '.tour-debit',
      title: 'Débito a ser pago.',
      content: 'Aqui estão as informações da infração ou despesa.',
      disableBeacon: true,
      styles: {
        //this styles override the styles in the props
        options: {
          primaryColor: '#05CC98',
          arrowColor: '#05CC98',
        }
      },
    },
    { 
      placement: 'left',
      target: '.tour-debit-check',
      title: 'Escolha e fique livre de dívidas.',
      content: 'Clique aqui para selecionar o que deseja quitar.',
      disableBeacon: true,
      styles: {
        //this styles override the styles in the props
        options: {
          primaryColor: '#05CC98',
          arrowColor: '#05CC98',
        }
      },
    },
  ]
  // Array of infractions
  const [infractions, setInfractions] = useState([]);
  useEffect(() => {
    // Capture infractions
    const currentInfractions = [];
    
    activeDebits.forEach(function(ticket) {
      ticket['infractions'].forEach(function(infraction){
        const currentInfraction = infraction;
        currentInfraction['ticket'] = ticket['digitable_line'];
        currentInfractions.push(currentInfraction);
      });
    });
    setInfractions(currentInfractions)
  }, []);

  const handleToggle = (infraction) => {  

    const currentIndex = paymentsChecked.findIndex((item) => item.ticket == infraction.ticket);

    let newPaymentsChecked = [];
    if (currentIndex === -1) {
      let newInfractions = infractions.map(item => {
        if (item.ticket == infraction.ticket){
          return item;
        }
      })
      newPaymentsChecked = [...paymentsChecked, ...newInfractions];
    } else {
      newPaymentsChecked = paymentsChecked.map(item => {
        if (item.ticket != infraction.ticket){
          return item;
        }
      })
    }
    newPaymentsChecked = newPaymentsChecked.filter(item => item != null)
    setPaymentsChecked(newPaymentsChecked);
  }

  function isChecked(payment){
    return paymentsChecked.findIndex(p => p.ticket === payment.ticket) !== -1;
  }

  // BRL currency formatting
  function formatCurrency(value) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
  }

  return (
      <div className={classes.root}>
        {infractions.length > 0 ?
        (
        <Grid className={classes.debtListContainer} container>

          {/* Tour component */}
          { currentUser &&
            <Joyride
              steps={steps}
              run={currentUser.sign_in_count <= 1 && true}
              continuous
              disableOverlayClose
              locale= {{next: 'Avançar', back: 'Voltar', last: 'Finalizar'}}
            />
          }

          <Grid className={classes.title} item xs={12}>
            <h1>Selecione os débitos que deseja pagar</h1>
          </Grid>
          
          {/* <Grid xs={1} item>
            <span className="tour-help"/>
          </Grid> */}
          <Hidden smUp>
            {[
              {num: 6},{num: 4},{num: 2},
            ].map((grid) =>
              <Grid item xs={grid['num']} className={classes.rows}>
                <Typography variant='subtitle1'>{'\u00A0'}</Typography>
              </Grid>
            )}
            <Grid item xs={6}>
              <Typography className={classes.rows} variant='subtitle1'>Descrição</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.rows} variant='subtitle1'>VALOR</Typography>
            </Grid>
            <Grid className={classes.rows} item xs={2}></Grid>
          </Hidden>

          <Hidden xsDown>
            {[
              {num: 6},{num: 2},{num: 2},{num: 2},
            ].map((grid) =>
              <Grid item sm={grid['num']} className={classes.rows}>
                <Typography variant='subtitle1'>{'\u00A0'}</Typography>
              </Grid>
            )}
            <Grid item sm={6} className={classes.rows}>
              <Typography variant='subtitle1'>Descrição</Typography>
            </Grid>
            <Grid item sm={2} className={classes.rows}>
              <Typography variant='subtitle1'>Tipo</Typography>
            </Grid>
            <Grid item sm={2} className={classes.rows}>
              <Typography variant='subtitle1'>VALOR</Typography>
            </Grid>
            <Grid item sm={2} className={classes.rows}/>
          </Hidden>
          {infractions.map((debt, i) => {
            return (
              <Grid xs={12} item className='tour-debit' >
                <Grid container onClick={()=>handleToggle(debt)} disableGutters={true} key={debt.id}>
                  <Hidden smUp>
                    <Grid item xs={6} className={classes.rows}>
                      <Typography variant='body1'>{debt.description != 'MORA MULTA' ? `${'\u00A0'} ${'\u00A0'} ${debt.description} `: debt.description}</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.rows}>
                      <Typography variant='body1'>{debt.value && `R$ ${debt.value}`}</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.rows} >
                      <GreenCheckbox name="checkedDebit" checked={isChecked(debt)}/>
                    </Grid>
                  </Hidden>

                  <Hidden xsDown>
                    <Grid item sm={6} className={classes.rows}>
                      <Typography variant='body1'>{debt.description && debt.description}</Typography>
                    </Grid>
                    <Grid item sm={2} className={classes.rows}>
                      <Typography variant='body1'>{debt.issuing_agency && debt.issuing_agency}</Typography>
                    </Grid>
                    <Grid item sm={2} className={classes.rows}>
                      <Typography variant='body1'>{debt.value && formatCurrency(debt.value)}</Typography>
                    </Grid>
                    <Grid item sm={2} className={classes.rows}>
                      <GreenCheckbox name="checkedDebit" checked={isChecked(debt)} className='tour-debit-check'/>
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            )})
          }
        </Grid>
      ):
      (
        <Grid className={classes.debtListContainer} container>
          <Grid className={classes.debtsNotFound} item>
            <img className={classes.iconNotFound} src={ornamentTwo}/> 
            <Typography variant='h1' className={classes.titleNotFound}>
              Não há débitos disponíveis para pagamento.
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  FormHelperText,
  Box,
  Grid,
  Link,
  Hidden,
} from "@material-ui/core";
import Header from "../components/Header";
import OrderDetails from "../components/OrderDetails";
import CardForm from "../components/CardForm";
import OrderPlaced from "../components/OrderPlaced";
import sucessIcon from "images/sucess-icon.svg";
import errorIcon from "images/error-icon.svg";
import axios from "axios";
import swal from "sweetalert";
import BillingAddressForm from "../components/BillingAddressForm";
import Footer from "../components/Footer";
import LoadingButton from "../components/LoadingButton";
import ModalForm from "../components/ModalForm";
import Loading from "../components/Loading";

const GreenCheckbox = withStyles({
  root: {
    color: "default",
    "&$checked": {
      color: "#05CC98",
    },
  },
  checked: {},
})((props) => <Checkbox {...props} />);

const GreenRadio = withStyles({
  root: {
    color: "default",
    "&$checked": {
      color: "#05CC98",
    },
  },
  checked: {},
})((props) => <Radio {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {},
  orderDetails: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "#E4EAF9",
  },
  orderPlaced: {
    alignContent: "center",
    [theme.breakpoints.up("xl")]: {
      minHeight: "1400px",
    },
    [theme.breakpoints.down("lg")]: {
      minHeight: "600px",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "500px",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "450px",
    },
  },
  paymentContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  paymentBox: {
    width: "100%",
    margin: "25px 0 150px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: 35,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "unset",
    },
  },
  selectContainer: {
    textAlign: "initial",
    [theme.breakpoints.up("sm")]: {
      minWidth: "500px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
    },
  },
  actionsContatiner: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "500px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
    },
  },
  recommended: {
    "&::after": {
      backgroundRepeat: "no-repeat",
      content: `'Melhor opção'`,
      color: "#00B26C",
      height: "35px",
      width: "140px",
      fontSize: "16px",
      backgroundSize: "45px",
      paddingTop: "8px",
      paddingLeft: "50px",
      marginLeft: "8px",
      [theme.breakpoints.down("sm")]: {
        width: 0,
        content: `''`,
        paddingLeft: "30px",
        backgroundSize: "30px",
        marginTop: "10px",
      },
    },
  },
  checkTerms: {
    textAlign: "initial",
    marginTop: 100,
  },
  payButton: {
    height: 50,
    width: "100%",
    backgroundColor: "#239CC4",
    marginTop: "10px",
    marginBottom: "50px",
    borderRadius: "10px",
    opacity: 1,
    "& span": {
      color: "#fff",
      fontSize: 18,
      textTransform: "none",
    },
  },
  title: {
    marginBottom: "20px",
    marginTop: "20px",
    fontSize: 30,
    fontFamily: "Noyh",
    fontWeight: 900,
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
    },
  },
}));

export default function Payment(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  // Attributes
  const currentUser = props?.currentUser || [];

  const reportContent = location?.state?.reportContent || undefined;
  const inspectionRecoveryId =
    location?.state?.reportContent?.inspection_id?.value || null;

  // Servico a ser pago
  const serviceToPay = location?.state?.serviceToPay || {};
  // Total a pagar
  const amountsToPay = location?.state?.serviceToPay.price || {};

  // const [parcels, setParcels] = React.useState({});
  const [paymentSuccess, setPaymentSuccess] = React.useState(false);

  // Credit card fields
  const [card, setCard] = useState({
    number: "",
    name: "",
    expiry: "",
    focus: "",
    cvc: "",
    // installments: "",
  });

  // Billing address fields
  const [address, setAddress] = useState({
    zipCode: "",
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
  });

  // Loading
  const [loading, setLoading] = useState(false);

  // Erros fields
  const [errors, setErrors] = useState({});

  //  Parcels selection
  // const onChangeInstallments = (event) => {
  //   const { value } = event.target;
  //   setCard({ ...card, installments: value.split(";", 2)[0] });
  // };

  // Checking usage terms
  const [checkTerms, setCheckTerms] = useState(false);
  const handleChange = (event) => {
    setCheckTerms(event.target.checked);
  };

  // Debt payment method
  function pay() {
    setErrors({});
    if (checkTerms) {
      setLoading(true);
      axios
        .post(`/api/v1/payments/pay_debt.json`, {
          credit_card: {
            card_number: card.number,
            card_holder_name: card.name,
            validate_card: card.expiry,
            cvv: card.cvc,
            // installments: card.installments,
          },
          billing_address_attributes: {
            number: address.number,
            address: address.street,
            neighborhood: address.neighborhood,
            city: address.city,
            state: address.state,
            complement: address.complement,
            zip_code: address.zipCode.split("-").join(""),
          },
          vehicle_attributes: {
            license_plate: reportContent?.vehicle?.license_plate.value,
          },
          services_to_paid_attributes: {
            services: {
              inspectionRecoveryId,
              amountsToPay,
            },
          },
        })
        .then((res) => {
          setLoading(false);
          swal({
            title: "Pagamento Realizado com sucesso!",
            icon: `${sucessIcon}`,
          });
          setPaymentSuccess(res.data.response);
        })
        .catch((error) => {
          setLoading(false);
          // Filds erros
          if (error.response.status == 422 && error?.response?.data?.errors) {
            setErrors(error.response.data.errors);
            swal({
              title: "Verifique os campos!",
              text: "Existem campos não preenchidos!",
              icon: `${errorIcon}`,
            });
            // Pendencia de API para tratar os erros
          } else if (error.response.status == 404) {
            swal({
              title: "Info",
              text: "Campo inváido",
              icon: `${infoIcon}`,
            });
          } else if (
            error.response.status == 422 &&
            error?.response?.data?.error
          ) {
            swal({
              title: `${error.response.data.error}`,
              icon: `${errorIcon}`,
            });
          } else if (error.response.status == 500) {
            swal({
              title: `Serviço indisponível, tente novamente mais tarde.`,
              icon: `${errorIcon}`,
            });
          } else {
            swal({
              title: `${error}`,
              icon: `${errorIcon}`,
            });
          }
        });
    } else {
      setErrors({ terms: "Aceite os termos de uso." });
    }
  }

  // // Verification of the installment amount
  // useEffect(() => {
  //   if (amountsToPay > 0) {
  //     axios
  //       .get(
  //         `/api/v1/payments/installment_amount.json?total_debts=${amountsToPay}`
  //       )
  //       .then((res) => {
  //         setParcels(res.data.installments);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setParcels({ "": "parcelas indisponíveis" });
  //       });
  //   }
  // }, []);

  // Capture address complete
  useEffect(() => {
    axios
      .get(`/api/v1/billing_addresses.json?last=${true}`)
      .then((res) => {
        const returnAddress = {
          street: res.data.address,
          city: res.data.city,
          complement: res.data.complement,
          neighborhood: res.data.neighborhood,
          number: res.data.number,
          state: res.data.state,
          zipCode: res.data.zip_code,
        };
        setAddress({ ...address, ...returnAddress });
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  // Check if the user is a client
  useEffect(() => {
    if (props?.currentUser?.role.includes("admin", "super_admin")) {
      history.push("/");
    }
  }),
    [];

  // BRL currency formatting
  function formatCurrency(value) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  }

  return (
    <div className={classes.root}>
      <Header currentUser={props?.currentUser} />
      {!paymentSuccess ? (
        <Grid container className={classes.paymentContainer}>
          <Hidden smDown>
            <Grid sm={4} lg={3} className={classes.orderDetails} item>
              {/* Order Informations  */}
              <OrderDetails
                serviceToPay={serviceToPay}
                totalToPay={amountsToPay}
                currentLicensePlate={
                  reportContent?.vehicle?.license_plate.value
                }
              />
            </Grid>
          </Hidden>
          <Grid xs={11} sm={7} lg={6} item>
            <Box boxShadow={3} className={classes.paymentBox}>
              <CardForm
                card={card}
                setCard={setCard}
                errors={errors}
                setErrors={setErrors}
              />
              {/* <div style={{ display: "flex", justifyContent: "center" }}>
                <div className={classes.selectContainer}> */}
              {/* Selection of installments of debts */}
              {/* <FormControl
                    error={errors.installments ? true : false}
                    component="fieldset"
                  >
                    <FormLabel
                      component="legend"
                      style={{
                        color: "#536777",
                        fontSize: 18,
                        textAlign: "left",
                      }}
                    >
                      Parcele sua compra
                    </FormLabel>
                    <br />
                    <FormHelperText id="my-helper-text">
                      {errors.installments}
                    </FormHelperText>
                    <RadioGroup
                      aria-label="installments"
                      name="installments"
                      onChange={onChangeInstallments}
                    >
                      {parcels &&
                        Object.entries(parcels)
                          .reverse()
                          .map(([parcel, price], i) => (
                            <FormControlLabel
                              value={`${parcel};${formatCurrency(price)}`}
                              control={<GreenRadio />}
                              label={`${parcel}x de ${formatCurrency(
                                price
                              )} Sem juros`}
                              className={i == 0 ? classes.recommended : ""}
                            />
                          ))}
                    </RadioGroup>
                  </FormControl> */}
              {/* </div>
              </div> */}
              {/* Billing Address Form  */}
              {/* <div className={classes.selectContainer}> */}
              <Grid
                container
                style={{ maxWidth: 516, margin: "0 auto", padding: 8 }}
              >
                <Grid item>
                  <Typography className={classes.title}>
                    Endereço do titular do cartão
                  </Typography>

                  <BillingAddressForm
                    address={address}
                    setAddress={setAddress}
                    errors={errors}
                    setErrors={setErrors}
                  />
                </Grid>
              </Grid>

              {/* Agreement to accept terms of use */}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className={classes.actionsContatiner}>
                  <div className={classes.checkTerms}>
                    <FormControlLabel
                      style={{ display: "flex", justifyContent: "start" }}
                      control={
                        <GreenCheckbox
                          error={errors.terms ? true : false}
                          helperText={errors.terms}
                          checked={checkTerms}
                          onChange={handleChange}
                          name="checkTerms"
                        />
                      }
                      label={
                        <div
                          style={{
                            fontSize: "18px",
                            color: "#94A5B2",
                            opacity: 1,
                            letterSpacing: 0,
                            marginTop: "5px",
                          }}
                        >
                          <span>Concordo com </span>
                          <Link
                            style={{ color: "#536777" }}
                            target="_blank"
                            href="https://segsat.com/termos-de-uso-do-autovalid/"
                          >
                            termos de uso
                          </Link>
                        </div>
                      }
                    />
                  </div>
                  {errors.terms ? (
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                        marginBottom: "5px",
                      }}
                    >
                      {errors.terms}
                    </div>
                  ) : null}
                  {/* Payment button */}
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    className={classes.payButton}
                    fullwidth
                    onClick={pay}
                  >
                    Prosseguir para pagamento
                  </LoadingButton>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container justify="center" className={classes.orderPlaced}>
          <Grid xs={10} sm={8} md={6} lg={4} item>
            <Box boxShadow={3}>
              {/* Display of completed order */}
              <OrderPlaced
                paymentSuccess={paymentSuccess}
                vehicleInspectionId={inspectionRecoveryId}
                currentUser={props?.currentUser}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      {/* Loading icon */}
      <ModalForm open={loading}>
        <Loading />
      </ModalForm>
      <Footer />
    </div>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiStepIcon-root": {
      color: "#FFFFFF",
      border: "1px solid #A5A6AE",
      borderRadius: "20px",
    },
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "#00b26c",
      border: "1px solid transparent",
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Pagamento realizado",
    "Agendamento confirmado",
    "Inspeção veícular realizada",
  ];
}

export default function StepperStatus({ stepIndex }) {
  const classes = useStyles();
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Stepper
        style={{ backgroundColor: "transparent" }}
        activeStep={stepIndex}
      >
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

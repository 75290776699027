import * as React from "react";

function SvgComponent5(props) {
  return (
    <svg
      data-name="Component 33 \u2013 2"
      xmlns="http://www.w3.org/2000/svg"
      width={150.318}
      height={193.648}
      transform="scale(0.4)"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={0.5}
          y1={1}
          x2={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#00b26c" />
          <stop offset={1} stopColor="#239cc4" />
        </linearGradient>
      </defs>
      <path
        data-name="Path 2369"
        d="M4242.2 8734.3l-39.908-84.333h-10.7l46.248 97.734 8.75-.008 91.595-194.4h-10.7z"
        transform="translate(-4191.587 -8553.297)"
        fill="url(#prefix__a)"
      />
    </svg>
  );
}

export default SvgComponent5;

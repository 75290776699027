import * as React from "react";
import SvgComponent1 from '../components/loading/SvgComponent1';
import SvgComponent2 from '../components/loading/SvgComponent2';
import SvgComponent3 from '../components/loading/SvgComponent3';
import SvgComponent4 from '../components/loading/SvgComponent4';
import SvgComponent5 from '../components/loading/SvgComponent5';
import SvgComponent6 from '../components/loading/SvgComponent6';
import SvgComponent7 from '../components/loading/SvgComponent7';
import './../styles/loading.scss';

function Loading() {
  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <SvgComponent1 className='frames'/>
        <SvgComponent2 className='frames'/>
        <SvgComponent3 className='frames'/>
        <SvgComponent4 className='frames'/>
        <SvgComponent5 className='frames'/>
        <SvgComponent6 className='frames'/>
        <SvgComponent7 className='frames'/>
      </div>
    </div>
  );
}

export default Loading;

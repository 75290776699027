import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import marca from "images/marca_icon.svg";
import { Container, Grid, Typography, Link } from "@material-ui/core";

import backgroundFooter from "images/background_footer.svg";
import ModalForm from "../components/ModalForm";
import SupportForm from "../components/SupportForm";
import ornamentOne from "images/enfeite1_icon.svg";
// import parviLogo from "images/parvi_logo.png";
// import segsatLogo from "images/segsat_logo.png";
// import phoneIcon from 'images/phone_icon.svg';
// import pinIcon from 'images/pin_icon.svg';
// import mailIcon from 'images/mail_icon.svg';
// import fb_icon from 'images/face_icon_2.svg';
// import insta_icon from 'images/insta_icon_2.svg';
// import twitter_icon from 'images/twitter_icon_2.svg';
import { styles } from "../../components/FileField";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url('${backgroundFooter}')`,
    backgroundSize: "100%",
  },
  footer: {
    minHeight: "150px",
  },
  brands: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },
  brand: {
    marginTop: "50px",
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "35%",
      marginTop: "unset",
    },
  },
  ornament: {
    backgroundImage: `url('${ornamentOne}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
}));

function Footer() {
  const classes = useStyles();
  const [support, setSupport] = React.useState(false);

  const handleSupportOpen = () => {
    setSupport(true);
  };

  return (
    <Container className={classes.root} maxWidth="xl">
      <Grid className={classes.footer} container>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <span
            style={{ color: "#fff", fontSize: "14px", marginBottom: "5px" }}
          >
            by
          </span>
          <img
            src={
              "https://segsat.com/wp-content/uploads/2021/06/Segsat-logo_branco.svg"
            }
            alt="Marca"
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={4} className={classes.ornament} />

        {/* <Grid item xs={8}> */}
        {/* <Grid item xs={4}/> */}
        {/* <Grid item xs={12} sm={6} className={classes.brands}>
          <div>
            <img src={parviLogo}  className={classes.brand}/>
          </div>
          <div>
            <img src={segsatLogo} className={classes.brand}/>
          </div> */}
        {/* <Typography variant='h1'>Est eligendi</Typography>
            <Typography>
              Optio cumque nihil impedit
              quo minus id quod maxime placeat
              facere possimus, omnis voluptas
              assumenda est, omnis dolor repellendus.
            </Typography><br/> */}

        {/* <a target="_blank" href='https://www.facebook.com/' className={classes.socialNetwork}>
                <img src={fb_icon} alt='facebook'/>
              </a>
              <a target="_blank"  href='https://www.twitter.com/' className={classes.socialNetwork}>
                <img src={twitter_icon} alt='twitter'/>
              </a>
              <a target="_blank" href='https://www.instagram.com/' className={classes.socialNetwork}>
                <img src={insta_icon} alt={'instagram'}/>
              </a> */}
        {/* </div> */}
        {/* <div className={classes.informationItens}> */}
        {/* <Typography variant='h1'>Sint et</Typography>
            <div className={classes.contactionItens}>
              <img className={classes.contactIcon} src={pinIcon}/>
              <Typography>Itaque earum rerum, 42</Typography>
            </div>
            <div className={classes.contactionItens}>
              <img className={classes.contactIcon} src={phoneIcon}/>
              <Typography>+ 1 234 5678 90</Typography>
            </div>
            <div className={classes.contactionItens}>
              <img className={classes.contactIcon} src={mailIcon}/>
              <Typography>info@info.com</Typography>
            </div>
          </div>
          <div className={classes.informationItens}>
            <Typography>Copyright 2021. RDMPPS All Rights Reserved.</Typography> */}
        {/* </div> */}
        {/* </Grid> */}
      </Grid>
    </Container>
  );
}
export default Footer;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Paper } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CountUp from "react-countup";
import Header from "../components/Header";
import SearchContainer from "../components/SearchContainer";
import Footer from "../components/Footer";
import magnifyingGlassIcon from "images/magnifying_glass_icon.svg";
import economizeIcon from "images/economize_icon.svg";
import identifiedProblems from "images/problems_icon.svg";
import inspectionIcon from "images/inspection_icon.svg";
import calendarIcon from "images/calendar.svg";
import markedCalendarIcon from "images/marked_calendar.svg";
import AboutUs from "../components/AboutUs";
import Services from "../components/Services";
import GenericCarousel from "../components/GenericCarousel";

const useStyles = makeStyles((theme) => ({
  root: {},
  aboutContainer: {
    display: "flex",
    background: "#239cc4",
  },
  companiesContainer: {
    display: "flex",
    background: "#fafafa",
  },
  title: {
    fontFamily: "Noyh",
    fontWeight: 900,
    color: "#012F47",
    paddingTop: "30px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "60px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "80px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "45px",
    },
  },
  activitiesContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    backgroundColor: "#239cc4",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  activityBox: {
    // margin: '50px',
    backgroundColor: "transparent",
    [theme.breakpoints.up("xl")]: {
      marginTop: "50px",
      marginBottom: "70px",
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "35px",
      marginBottom: "70px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
      marginBottom: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "25px",
      marginBottom: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "45px",
      "&:nth-child(1)": {
        marginTop: "58px",
      },
    },
  },
  activityTitle: {
    color: "#FFF",
    fontWeight: 900,
    letterSpacing: 0,
    [theme.breakpoints.up("xl")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("lg")]: {
      height: "50px",
      fontSize: "60px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "45px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  activityIcon: {
    marginBottom: "15px",
    [theme.breakpoints.up("xl")]: {
      height: "100px",
    },
    [theme.breakpoints.down("lg")]: {
      height: "80px",
    },
    [theme.breakpoints.down("md")]: {
      height: "70px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "85px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "85px",
    },
  },
  activityDescription: {
    color: "#333333",
    textAlign: "center",
    lineHeight: "25px",
    letterSpacing: "0",
    fontWeight: 900,
    [theme.breakpoints.up("xl")]: {
      // fontSize: '150px',
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  activitySubDescription: {
    color: "#FFFFFF",
    opacity: "0.5",
    // Corrigir pra fonte roboto
    textAlign: "center",
    [theme.breakpoints.up("xl")]: {
      // fontSize: '150px',
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  operationContainer: {
    backgroundColor: "transparent",
  },
  operationTitle: {
    fontFamily: "Noyh",
    fontWeight: 900,
    color: "#012F47",
    marginTop: "30px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "60px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "80px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "45px",
    },
  },
  operationSubTitle: {
    color: "#012F47",
    fontSize: "18px",
    fontWeight: 400,
    marginBottom: "30px",
    maxWidth: "550px",
  },
  operatingCards: {
    display: "flex",
    marginBottom: "85px",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    // // justifyContent: 'space-around',
    // // flexDirection: 'row',
    // [theme.breakpoints.up('xl')]: {
    //   // margin: '40px 0 100px',
    // },
    // [theme.breakpoints.down('lg')]: {
    //   // justifyContent: 'space-evenly',
    //   // marginBottom: '90px',
    // },
    // [theme.breakpoints.down('md')]: {
    //   // justifyContent: 'center',
    //   // marginBottom: '70px',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   // marginBottom: '30px',

    // },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  operationBox: {
    minHeight: "215px",
    width: "280px",
    // [theme.breakpoints.up('xl')]: {
    // width: '300px',
    // },
    // [theme.breakpoints.down('lg')]: {
    // width: '310px',
    // },
    // [theme.breakpoints.down('md')]: {
    // width: '240px',
    // },
    // [theme.breakpoints.down('sm')]: {
    // width: '240px',
    // },
    // [theme.breakpoints.down('xs')]: {
    // maxWidth: '270px',
    // margin: '0px auto'
    // height: '190px',
    // },
  },
  operationIcon: {
    marginTop: "40px",
    marginBottom: "20px",
    [theme.breakpoints.up("xl")]: {},
    [theme.breakpoints.down("lg")]: {
      height: "65px",
    },
    [theme.breakpoints.down("md")]: {
      height: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "45px",
    },
    [theme.breakpoints.down("xs")]: {},
  },
  operationDescription: {
    opacity: 0.5,
    lineHeight: "20px",
    letterSpacing: 0,
    color: "#333333",
    display: "inline-block",
    fontFamily: "Noyh",
    fontWeight: "normal",
    marginBottom: "20px",
    [theme.breakpoints.up("xl")]: {},
    [theme.breakpoints.down("lg")]: {
      maxWidth: "135px",
      fontSize: "18px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "135px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "125px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "170px",
    },
  },
  arrowRight: {
    color: "#00B26C",
    // alignItems: 'center',
    [theme.breakpoints.up("xl")]: {
      fontSize: "150px",
      // marginTop: '20px',
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "165px",
      // marginTop: '35px',
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "150px",
      // marginTop: '20px',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "80px",
      // marginTop: '40px',
    },
    [theme.breakpoints.down("xs")]: {
      transform: "rotate(90deg)",
      // marginTop: 'unset',
      fontSize: "80px",
    },
  },
}));

export default function Home(props) {
  const classes = useStyles();
  var start_date = new Date("07/01/2022");
  var today = new Date();  
  // To calculate the time difference of two dates
  var Difference_In_Time = today.getTime() - start_date.getTime();
  // To calculate the no. of days between two dates
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  
  const vistorias = Difference_In_Days * 40;
  const valores = vistorias * 15;
  const erros = vistorias * 5;

  const companiesList = [
    {
      name: "america-ford",
      imgUrl:
        "https://www.parvi.com.br/wp-content/uploads/2020/08/america-ford.png",
    },
    {
      name: "audi-center-recife",
      imgUrl:
        "https://www.parvi.com.br/wp-content/uploads/2020/08/audi-center-recife.png",
    },
    {
      name: "bremen",
      imgUrl:
        "https://www.parvi.com.br/wp-content/uploads/2020/10/WhatsApp_Image_2020-10-06_at_12.59.56-removebg-preview-1-1.png",
    },
    {
      name: "https://www.parvi.com.br/wp-content/uploads/2020/08/fiori-fiat.png",
      imgUrl:
        "https://www.parvi.com.br/wp-content/uploads/2020/08/fiori-fiat.png",
    },
    {
      name: "fiori-jeep",
      imgUrl:
        "https://www.parvi.com.br/wp-content/uploads/2020/08/fiori-jeep.png",
    },
    {
      name: "lexus",
      imgUrl: "https://www.parvi.com.br/wp-content/uploads/2020/08/lexus.png",
    },
    {
      name: "mardisa",
      imgUrl: "https://www.parvi.com.br/wp-content/uploads/2020/08/mardisa.png",
    },
    {
      name: "parvi-mercedes",
      imgUrl:
        "https://www.parvi.com.br/wp-content/uploads/2020/08/parvi-mercedes.png",
    },
    {
      name: "pateo-hyundai",
      imgUrl:
        "https://www.parvi.com.br/wp-content/uploads/2020/08/pateo-hyundai.png",
    },
    {
      name: "",
      imgUrl: "https://www.parvi.com.br/wp-content/uploads/2020/08/toyolex.png",
    },
    {
      name: "toyolex",
      imgUrl:
        "https://www.parvi.com.br/wp-content/uploads/2020/08/way-jaguar.png",
    },
    {
      name: "",
      imgUrl:
        "https://www.parvi.com.br/wp-content/uploads/2020/08/jaguar-way.png",
    },
    {
      name: "jaguar-way",
      imgUrl:
        "https://www.parvi.com.br/wp-content/uploads/2020/08/seminovos-autoparvi.png",
    },
    // {
    //   name: "parvi-locadora",
    //   imgUrl:
    //     "https://www.parvi.com.br/wp-content/uploads/2020/08/parvi-locadora.png",
    // },
    // {
    //   name: "parvi-blindados",
    //   imgUrl:
    //     "https://www.parvi.com.br/wp-content/uploads/2020/08/parvi-blindados.png",
    // },
    // {
    //   name: "parvi-consorcio",
    //   imgUrl:
    //     "https://www.parvi.com.br/wp-content/uploads/2020/08/parvi-consorcio.png",
    // },
    // {
    //   name: "parvi-corretora",
    //   imgUrl:
    //     "https://www.parvi.com.br/wp-content/uploads/2020/10/parvi-corretora.png",
    // },
  ];

  return (
    <div className={classes.root}>
      <Header currentUser={props.currentUser} />

      <div style={{ backgroundColor: "#012f47" }}>
        <Container maxWidth="xl" style={{ padding: "unset" }}>
          <SearchContainer />
        </Container>
      </div>

      {/* Start - Session activities */}
      <div style={{ backgroundColor: "#239CC5" }}>
        <Container maxWidth="xl">
          <Grid className={classes.activitiesContainer} container>
            <Paper className={classes.activityBox} elevation={0}>
              <div style={{ maxWidth: "360px", margin: "0 auto" }}>
                <img
                  className={classes.activityIcon}
                  src={magnifyingGlassIcon}
                />
                <CountUp start={0} end={vistorias} delay={0} duration={7}>
                  {({ countUpRef }) => (
                    <Typography
                      className={classes.activityTitle}
                      variant="h1"
                      ref={countUpRef}
                    />
                  )}
                </CountUp>
                <Typography
                  className={classes.activityDescription}
                  variant="subtitle1"
                >
                  Vistorias
                  <br />
                  realizadas
                </Typography>
                <Typography
                  className={classes.activitySubDescription}
                  variant="body1"
                >
                  Sempre prestando o melhor serviço e atendimento ao nosso
                  cliente.
                </Typography>
              </div>
            </Paper>

            <Paper className={classes.activityBox} elevation={0}>
              <div style={{ maxWidth: "360px", margin: "0 auto" }}>
                <img className={classes.activityIcon} src={economizeIcon} />
                <CountUp end={valores} delay={0} separator="." duration={7}>
                  {({ countUpRef }) => (
                    <Typography
                      className={classes.activityTitle}
                      variant="h1"
                      ref={countUpRef}
                    />
                  )}
                </CountUp>
                <Typography
                  className={classes.activityDescription}
                  variant="subtitle1"
                >
                  R$ <br />
                  Economizados
                </Typography>

                <Typography
                  className={classes.activitySubDescription}
                  variant="body1"
                >
                  Poupando nossos clientes de erros, já fizemos economizarem
                  bastante dinheiro!
                </Typography>
              </div>
            </Paper>

            <Paper className={classes.activityBox} elevation={0}>
              <div style={{ maxWidth: "360px", margin: "0 auto" }}>
                <img
                  className={classes.activityIcon}
                  src={identifiedProblems}
                />
                <CountUp start={0} end={erros} suffix="+" delay={0} duration={7}>
                  {({ countUpRef }) => (
                    <Typography
                      className={classes.activityTitle}
                      variant="h1"
                      ref={countUpRef}
                    />
                  )}
                </CountUp>
                <Typography
                  className={classes.activityDescription}
                  variant="subtitle1"
                >
                  Problemas
                  <br />
                  identificados
                </Typography>
                <Typography
                  className={classes.activitySubDescription}
                  variant="body1"
                >
                  Quem avisa, amigo é!
                </Typography>
              </div>
            </Paper>
          </Grid>
        </Container>
      </div>
      {/* </Container> */}
      {/* End - Session activities */}
      {/* Start - Session services */}
      {/* <div> */}
        <Services />
      {/* </div> */}
      {/* End - Session services */}
      {/* Start - Session operation */}
      <div style={{ backgroundColor: "#FFF" }}>
        <Container maxWidth="xl">
          <Grid className={classes.operationContainer} container id="operation">
            <Grid xs={12} item align="center">
              <Typography className={classes.operationTitle}>
                Como funciona
              </Typography>
              <Typography className={classes.operationSubTitle}>
                Simples, rápido e fácil. Com poucos cliques você terá acesso a
                todo o histórico do veículo que deseja.
              </Typography>
            </Grid>

            <Grid className={classes.operatingCards} item xs={12}>
              <Paper className={classes.operationBox} elevation={3}>
                <div>
                  <img className={classes.operationIcon} src={inspectionIcon} />
                </div>
                <Typography className={classes.operationDescription}>
                  Digite a sua placa
                </Typography>
              </Paper>

              <ChevronRightIcon className={classes.arrowRight} />

              <Paper className={classes.operationBox} elevation={3}>
                <div>
                  <img
                    className={classes.operationIcon}
                    style={{ height: "60px" }}
                    src={calendarIcon}
                  />
                </div>

                <Typography className={classes.operationDescription}>
                  Agende a sua vistoria
                </Typography>
              </Paper>

              <ChevronRightIcon className={classes.arrowRight} />

              <Paper className={classes.operationBox} elevation={3}>
                <div>
                  <img
                    className={classes.operationIcon}
                    src={markedCalendarIcon}
                  />
                </div>
                <Typography className={classes.operationDescription}>
                  Realize a vistoria e descubra se há algo de errado com o
                  carro.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* End - Session operation */}

      {/* Start - Session about us */}
      <div style={{ backgroundColor: "#E4EAF9" }}>
        <Container maxWidth="xl">
          <AboutUs />
        </Container>
      </div>
      {/* End - Session depositions us */}
      <div style={{ backgroundColor: "##fafafa" }}>
        <Typography className={classes.title}>Nossos Parceiros</Typography>
        <Container maxWidth="xl">
          <Grid className={classes.companiesContainer} container>
            <Grid item sm={12} xs={12}>
              <GenericCarousel itens={companiesList} itensPerPage={4} />
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* Start - Session footer */}
      <Footer />
      {/* End - Session footer */}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Grid, Button, Typography, Hidden } from "@material-ui/core";
import userIcon from "images/user_alt.svg";
import documentIcon from "images/document_icon.svg";
import phoneIcon from "images/phone_icon.svg";
import mailIcon from "images/mail_icon.svg";
import ModalForm from "../components/ModalForm";
import Loading from "../components/Loading";
import swal from "sweetalert";
import sucessIcon from "images/sucess-icon.svg";
import errorIcon from "images/error-icon.svg";
import infoIcon from "images/info-icon.svg";
import { TextMaskPhone } from "../components/TextMaskCustom";
import BillingAddressForm from "./BillingAddressForm";

const useStyles = makeStyles((theme) => ({
  root: {},
  profileContainer: {
    display: "flex",
    flexDirection: "column",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  },
  imgBox: {
    marginRight: 25,
  },
  inputBox: {
    display: "contents",
  },
  buttonSubimit: {
    textAlign: "left",
    marginLeft: 46,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginLeft: "unset",
    },
    "& button": {
      color: "#fff",
      width: "100px",
      borderRadius: "10px",
      fontWeight: 900,
      backgroundColor: "#239CC4",
    },
  },
  addressContainer: {
    marginLeft: "45px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "unset !important",
    },
  },
  textField: {
    "& label.Mui-focused": {
      color: "#536777",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset, &:hover fieldset": {
        borderColor: "#05CC98",
        opacity: 0.24,
      },
    },
  },
}));

export default function ProfileForm(props) {
  const classes = useStyles();

  // Data current user
  const currentUser = props?.currentUser || [];

  // Loading
  const [loading, setLoading] = useState(false);

  // Data user
  const [user, setUser] = useState({
    id: currentUser.id,
    name: currentUser.name,
    last_name: currentUser.last_name,
    email: currentUser.email,
    phone: currentUser.phone,
  });

  // Billing address fields
  const [address, setAddress] = useState({
    zipCode: currentUser.last_billing_address.zip_code,
    street: currentUser.last_billing_address.address,
    number: currentUser.last_billing_address.number,
    complement: currentUser.last_billing_address.complement,
    neighborhood: currentUser.last_billing_address.neighborhood,
    city: currentUser.last_billing_address.city,
    state: currentUser.last_billing_address.state,
  });

  // Erros
  const [userErrors, setUserErrors] = useState({});

  // Change data user info
  function changeProfile(e) {
    setLoading(true);
    e.preventDefault();
    props
      .updateUser(user, address)
      .then((res) => {
        swal({
          title: "Sucesso",
          text: res.data.message,
          icon: sucessIcon,
        });
        setUserErrors({});
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          var title = "Info";
          var icon = infoIcon;
        } else {
          var title = "Erro";
          var icon = errorIcon;
          setUserErrors(err.response.data.errors);
        }
        swal({
          title: title,
          text: err.response.data.message,
          icon: icon,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <form onSubmit={changeProfile} noValidate style={{ width: "100%" }}>
          <Grid xs={12} className={classes.profileContainer} item>
            <div className={classes.inputContainer}>
              <Hidden xsDown>
                <div className={classes.imgBox}>
                  <img src={userIcon} />
                </div>
              </Hidden>

              <div className={classes.inputBox}>
                <TextField
                  id="name"
                  name="name"
                  label="Nome"
                  variant="outlined"
                  value={user.name}
                  className={classes.textField}
                  error={userErrors.name ? true : false}
                  helperText={userErrors.name ? userErrors.name.join(", ") : ""}
                  onChange={handleChange}
                  fullWidth
                />
              </div>
            </div>

            <div className={classes.inputContainer}>
              <Hidden xsDown>
                <div className={classes.imgBox}>
                  <img style={{ visibility: "hidden" }} src={userIcon} />
                </div>
              </Hidden>

              <div className={classes.inputBox}>
                <TextField
                  id="last_name"
                  name="last_name"
                  label="Sobrenome"
                  variant="outlined"
                  value={user.last_name}
                  error={userErrors.last_name ? true : false}
                  className={classes.textField}
                  helperText={
                    userErrors.last_name ? userErrors.last_name.join(", ") : ""
                  }
                  onChange={handleChange}
                  fullWidth
                />
              </div>
            </div>

            <div className={classes.inputContainer}>
              <Hidden xsDown>
                <div style={{ marginRight: 22 }}>
                  <img src={documentIcon} />
                </div>
              </Hidden>

              <div className={classes.inputBox}>
                <TextField
                  id="document"
                  name="document"
                  label="Documento"
                  variant="outlined"
                  value={currentUser.document}
                  className={classes.textField}
                  error={userErrors.document ? true : false}
                  helperText={
                    userErrors.document ? userErrors.document.join(", ") : ""
                  }
                  fullWidth
                  disabled
                />
              </div>
            </div>

            <div className={classes.inputContainer}>
              <Hidden xsDown>
                <div className={classes.imgBox}>
                  <img src={mailIcon} />
                </div>
              </Hidden>

              <div className={classes.inputBox}>
                <TextField
                  id="email"
                  label="E-mail"
                  name="email"
                  variant="outlined"
                  value={user.email}
                  error={userErrors.email ? true : false}
                  helperText={
                    userErrors.email ? userErrors.email.join(", ") : ""
                  }
                  className={classes.textField}
                  onChange={handleChange}
                  fullWidth
                  disabled
                />
              </div>
            </div>

            <div className={classes.inputContainer}>
              <Hidden xsDown>
                <div className={classes.imgBox}>
                  <img src={phoneIcon} />
                </div>
              </Hidden>

              <div className={classes.inputBox}>
                <TextField
                  id="phone"
                  name="phone"
                  label="Telefone"
                  variant="outlined"
                  value={user.phone}
                  error={userErrors.phone ? true : false}
                  helperText={
                    userErrors.phone ? userErrors.phone.join(", ") : ""
                  }
                  className={classes.textField}
                  InputProps={{ inputComponent: TextMaskPhone }}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  fullWidth
                />
              </div>
            </div>
            <div className={classes.addressContainer}>
              <div>
                <BillingAddressForm
                  address={address}
                  setAddress={setAddress}
                  errors={userErrors}
                  setErrors={setUserErrors}
                />
                <br />
              </div>
            </div>
            <div className={classes.buttonSubimit}>
              <Button
                variant="contained"
                size="large"
                type="submit"
                disableElevation
              >
                Enviar
              </Button>
            </div>
          </Grid>
        </form>
      </Grid>
      <ModalForm open={loading}>
        <Loading />
      </ModalForm>
    </div>
  );
}

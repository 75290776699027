import * as React from "react";

function SvgComponent7(props) {
  return (
    <svg
      data-name="Component 35 \u2013 2"
      xmlns="http://www.w3.org/2000/svg"
      width={150.318}
      height={193.648}
      transform="scale(0.4)"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={1}
          y1={0.5}
          x2={0}
          y2={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#00b26c" />
          <stop offset={1} stopColor="#239cc4" />
        </linearGradient>
      </defs>
      <path
        data-name="Path 2371"
        d="M4573.968 8729.96l-37.133-78.472h-15.571l46.356 97.963 12.727-.011 91.235-193.638h-15.558z"
        transform="translate(-4521.264 -8555.803)"
        fill="url(#prefix__a)"
      />
    </svg>
  );
}

export default SvgComponent7;

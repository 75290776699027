import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper } from "@material-ui/core";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LoadingButton from "../components/LoadingButton";
import infoIcon from "images/info-icon.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
    padding: "30px",
    minHeight: "330px",
  },
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    "& img": {
      marginBottom: "30px",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "22px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  information: {
    color: "#333333",
    marginTop: "30px",
    fontSize: "18px",
    "& a": {
      textDecoration: "none",
    },
  },
  subtitle: {
    fontSize: "Roboto",
    letterSpacing: "0px",
    color: "#536777",
    opacity: "1",
    marginTop: "20px",
  },
  description: {
    color: "#144167",
  },
  icon: {
    [theme.breakpoints.up("xl")]: {
      width: "30%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
  },
  button: {
    width: "100%",
    marginTop: "10px",
    marginBottom: "50px",
    borderRadius: "10px",
    opacity: 1,
    "& span": {
      fontSize: 16,
      textTransform: "none",
    },
  },
  footer: {
    width: "100%",
    bottom: "0",
  },
}));

export default function IntegrationError(props) {
  const classes = useStyles();

  return (
    <div>
      <Header currentUser={props.currentUser} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ margin: "150px 0" }}
      >
        <Grid item xs={10} sm={6}>
          <Paper>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={12} md={3} className={classes.box}>
                <img src={infoIcon} className={classes.icon} />
              </Grid>
              <Grid item xs={12} sm={12} md={9}>
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    Encontramos um problema.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.information}>
                    Não foi possível realizar a integração do seu pedido.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.information}>
                    {`Entraremos em contato pelo seu e-mail: ${props.currentUser.email}, para confirmamos o seu
                    agendamento.`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
}

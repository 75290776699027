import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ProfileForm from "../components/ProfileForm";
import ChangePasswordForm from "../components/ChangePasswordForm";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import axios from "axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fff",
    display: "flex",
    minHeight: "500px",
  },
  tabs: {
    height: 450,
    borderRight: `1px solid #00000019`,
    "& div": {
      marginTop: "15px",
      "& .MuiTabs-indicator": {
        backgroundColor: "#3232B2",
      },
    },
    "& button": {
      marginBottom: "30px",
    },
  },
  tab: {
    fontSize: "18px",
    fontFamily: "Noyh",
    fontWeight: "bold",
    textTransform: "none",
  },
}));

export default function VerticalTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function updateUser(user, address) {
    return axios.put(`/api/v1/users/${user.id}.json`, {
      user: {
        name: user.name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        password: user.password,
        password_confirmation: user.password_confirmation,
        current_password: user.current_password,
        billing_address: address,
      },
    });
  }

  return (
    <div className={classes.root}>
      <Grid container justify={"center"}>
        <Grid xs={3} item>
          <Tabs
            orientation="vertical"
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="Vertical menu"
            className={classes.tabs}
          >
            <Tab
              className={classes.tab}
              label="Editar Perfil"
              {...a11yProps(0)}
            />
            <Tab
              className={classes.tab}
              label="Trocar Senha"
              {...a11yProps(1)}
            />
          </Tabs>
        </Grid>
        <Grid xs={7} item>
          <TabPanel value={value} index={0}>
            <ProfileForm
              updateUser={updateUser}
              currentUser={props.currentUser}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChangePasswordForm
              updateUser={updateUser}
              currentUser={props.currentUser}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../components/Header'
import OrderList from '../components/OrderList'
import Footer from '../components/Footer'
import {
  Button,
} from '@material-ui/core'
import {
  Link
} from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  root:{
   width: '100%',
   height: '220px',
  },
  backButton:{
    height: 50,
    width: '200px',
    backgroundColor: '#239CC4',
    marginBottom: '20px',
    borderRadius: '10px',
    opacity: 1,
    '& span':{
      color: '#fff',
      fontSize: 18,
      textTransform: 'none'
    }
  },
}));

export default function Ordens(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header currentUser={props.currentUser} />
      <OrderList currentUser={props.currentUser}/>
      <Footer/>
    </div>
  );
}

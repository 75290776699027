import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Grid, Typography, Paper } from "@material-ui/core";

import image1 from "images/background_auto_valid_2.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "545px",
    backgroundColor: "#E4EAF9",
  },
  image: {
    maxWidth: "90%",
    margin: "25px 0px",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  title: {
    fontFamily: "Noyh",
    fontWeight: 900,
    color: "#012F47",
    paddingTop: "30px",
    // marginBottom: '15px',
    [theme.breakpoints.up("xl")]: {
      fontSize: "60px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "80px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "45px",
    },
  },
  content: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  description: {
    textAlign: "justify",
    marginBottom: "45px",
    maxWidth: "90%",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));

export default function AboutUs() {
  const classes = useStyles();
  return (
    <div className={classes.root} id="aboutUs">
      <Typography className={classes.title} variant="h4">
        Sobre nós
      </Typography>
      <div className={classes.content}>
        <Grid xs={12} item>
          <img className={classes.image} src={image1} />
        </Grid>
        <Grid xs={12} item>
          <Typography className={classes.description}>
            A <b style={{ color: "#00B26C" }}>Autovalid</b> é uma solução
            disruptiva no mercado de vistoria cautelar.
            <br /> Além das informações de histórico de leilão, restrições,
            débitos, conferencia de originalidade, analises de carroceria,
            pintura e estrutura do veículo, fornecemos o diagnóstico do motor e
            da parte eletrônica do automóvel, através de um dispositivo
            conectado no veículo e da nossa tecnologia.
            <br /> Nós utilizamos I.A. (inteligência artificial) para aprovação
            ou reprovação do laudo de vistoria, entregando bem mais
            assertividade e confiança para o nosso cliente e para o mercado
            automotivo.
            <br /> Nossas equipes formadas por peritos com experiencia de mais
            de 10 anos em vistorias automotivas, verificam mais de 100 ítens do
            nosso checklist, garantindo ainda mais qualidade das vistorias.
            <br /> Já estamos presentes nas capitais e regiões metropolitanas da
            Bahia, Sergipe, Pernambuco, Paraíba, Rio Grande do Norte, Amazonas e
            Roraima.
            <br /> A <b style={{ color: "#00B26C" }}>Autovalid</b> é da
            <a
              style={{ color: "#1050fc", textDecoration: "none" }}
              href="https://segsat.com/"
              target="_blank"
            >
              <b> Segsat</b>
            </a>
            , empresa que atua há 22 anos no ramo de tecnologia automotiva no
            Brasil.
          </Typography>
        </Grid>
      </div>
    </div>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory } from "react-router-dom";
import { Grid, Typography, Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("xl")]: {
      height: "600px",
    },
    [theme.breakpoints.down("lg")]: {
      height: "550px",
    },
    [theme.breakpoints.down("md")]: {
      height: "500px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "400px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "300px",
    },
  },
  checkIcon: {
    color: "#00B26C",
    marginTop: "20px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "48px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "45px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
    },
  },
  orderInformations: {
    marginTop: "15px",
    marginBottom: "35px",
  },
  title: {
    letterSpacing: "-1.01px",
    fontWeight: "normal",
    marginBottom: "15px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
    },
  },
  subTitle: {
    color: "#272833",
    lineHeight: 1,
    fontWeight: "normal",
    letterSpacing: "-0.29px",
    opacity: 0.7,
    [theme.breakpoints.up("xl")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  orderButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    marginBottom: "25px",
    [theme.breakpoints.up("xl")]: {
      width: "190px",
      fontSize: "18px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "160px",
      fontSize: "14px",
    },
    [theme.breakpoints.down("md")]: {
      width: "140px",
      fontSize: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "130px",
      fontSize: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "110px",
    },
  },
}));

export default function OrderPlaced(props = {}) {
  const classes = useStyles();
  let history = useHistory();

  const paymentData = props.paymentSuccess?.payment || {};
  const vehicleInspectionId = props.vehicleInspectionId || {};

  // function goCheckout() {
  //   history.push({
  //     pathname: `/order/${paymentData.id}`,
  //     state: {
  //       vehicleInspectionId: vehicleInspectionId,
  //     },
  //   });
  // }

  function goToScheduling() {
    history.push({
      pathname: `/orders/${vehicleInspectionId}/scheduling`,
      state: {
        currentUser: props.currentUser,
        vehicleInspectionId: vehicleInspectionId
      },
    });
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid xs={12} item>
          <CheckCircleIcon className={classes.checkIcon} />
          <div className={classes.orderInformations}>
            <Typography className={classes.title} variant="h1">
              Pagamento Efetuado!
            </Typography>
            <Typography className={classes.subTitle} variant="subtitle1">
              Seu pedido <b>#{paymentData ? paymentData.pg_token : ""}</b> foi
              feito com sucesso.
            </Typography>
            {/* <Typography className={classes.subTitle} variant='subtitle1'>Atualizaremos você por email.</Typography> */}
          </div>
          <Grid className={classes.orderButtons}>
            <Button
              style={{ backgroundColor: "#239CC4", color: "#FFF" }}
              className={classes.button}
              onClick={goToScheduling}
              size="large"
              variant="contained"
            >
              Agendar vistoria
            </Button>
            {/* <Button
              className={classes.button}
              href="/orders"
              size="large"
              variant="contained"
            >
              Fechar
            </Button> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

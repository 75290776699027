import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  MenuItem,
  Grid,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LoadingButton from "../components/LoadingButton";
import Autocomplete from "@material-ui/lab/Autocomplete";

import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import SchedulingConfirmation from "./SchedulingConfirmation";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  header: {
    position: "fixed",
    zIndex: "1",
  },
  title: {
    opacity: "1",
    fontSize: "18px",
    color: "#333333",
    fontWeight: "bold",
    marginBottom: "10px",
    textAlign: "left",
    [theme.breakpoints.up("xl")]: {},
    [theme.breakpoints.down("lg")]: {
      fontSize: "24px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  description: {
    textAlign: "left",
    marginBottom: "10px",
    opacity: 1,
  },
  formControl: {
    width: "100%",
    marginBottom: "20px",
  },
  buttonSchedule: {
    width: "100%",
    backgroundColor: "#239CC4",
    marginTop: "10px",
    marginBottom: "50px",
    borderRadius: "10px",
    opacity: 1,
    "& span": {
      color: "#fff",
      fontSize: 18,
      textTransform: "none",
    },
  },
  scheduleField: {
    padding: "20px",
  },
  // presentialInspection: {
  //   display: "flex",
  //   padding: "50px",
  //   minWidth: "700px",
  //   flexDirection: "column",
  //   "& h1": {
  //     opacity: "1",
  //     fontSize: "18px",
  //     color: "#333333",
  //     fontWeight: "bold",
  //     marginBottom: "10px",
  //   },
  //   "& body": {
  //     body: "sold red 1px",
  //   },
  // },
  // schedulingContainer: {
  //   border: "1px solid red",
  //   marginTop: "30px",
  // },
  footer: {
    position: "absolute",
    bottom: "0",
  },
}));

export default function Scheduling(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  registerLocale("pt", pt);

  const { id } = useParams();
  const vehicleInspectionId = location?.state?.vehicleInspectionId;

  const [centersCollection, setCentersCollection] = useState([]);
  const [datesCollection, setDatesCollection] = useState([]);
  const [hoursCollection, setHoursCollection] = useState([]);
  const [currentDate, setCurrentDate] = useState(null);

  const [centersFetiching, setCentersFetching] = useState(null);
  const [datesFetching, setDatesFetching] = useState(null);

  const initialSchedulingFields = {
    inspectionCenter: {
      id: "",
      name: "",
    },
    hour: null,
    date: null,
  };
  const [schedulingFields, setSchedulingFields] = useState(
    initialSchedulingFields
  );

  useEffect(() => {
    setCentersFetching(false);
    axios
      .get(`/api/v1/vehicle_inspection/inspection_centers`)
      .then(function (res) {
        let centers = res?.data?.available_inspection_centers || [];
        setCentersCollection(centers);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        setCentersFetching(true);
      });
  }, []);

  // Management of inspection centers
  function handleCenter(e) {
    let center = centersCollection.find(
      (center) => center.id === e.target.value
    );
    setSchedulingFields({
      ...schedulingFields,
      inspectionCenter: {
        id: center.id,
        name: center.name,
      },
      hour: null,
      date: null,
    });
    setHoursCollection([]);
    getSchedules(center.id);
  }

  // Check available schedules
  function getSchedules(centerId) {
    axios
      .get(
        `/api/v1/vehicle_inspection/available_schedules?center_id=${centerId}`
      )
      .then(function (res) {
        let schedules = res?.data?.available_schedules || [];
        setDatesCollection(schedules);
      })
      .catch(function (error) {});
  }

  // Date formatting for calendar
  function formatDateCalendar(dates) {
    const timeZoneOffset = new Date().getTimezoneOffset();
    const availableDates = [];

    for (let index = 0; index < dates.length; index++) {
      const item = dates[index];
      availableDates.push(
        new Date(Date.parse(item.data) + timeZoneOffset * 60 * 1000)
      );
    }
    return availableDates;
  }

  // Scheduling choice management
  function handleScheduling(date) {
    setSchedulingFields({
      ...schedulingFields,
      date: date.toISOString().split("T")[0],
      hour: null,
    });
    setCurrentDate(date);
    // const formattedDates = formatDateCalendar(datesCollection);
    // Busca o objeto de horarios da data selecionada
    const availableHours = datesCollection.find((item) => {
      if (item.data === date.toISOString().split("T")[0]) {
        return item;
      }
    });
    setHoursCollection(availableHours?.horarios || []);
  }

  // Go to confirmation page
  function goToConfirmation(e) {
    history.push({
      pathname: "/scheduling_confirmation",
      state: {
        currentUser: props.currentUser,
        vehicleInspectionId: id,
        schedule: schedulingFields,
      },
    });
  }

  return (
    <div classes={classes.root}>
      <Header classes={classes.header} currentUser={props.currentUser} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "600px" }}
      >
        <Paper elevation={3} style={{ maxWidth: "550px", margin: "60px 0" }}>
          <Grid container style={{ padding: "30px" }}>
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.title}>
                Vistoria Presencial
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.description}>
                Selecione a concessionária, data e hora de sua preferência.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                htmlFor="outlined-age-native-simple"
                style={{ textAlign: "left" }}
              >
                Local
              </InputLabel>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                disabled={centersCollection < 1}
              >
                <Select
                  // disabled={loading}
                  id="centro_vacinacao"
                  inputProps={{ "aria-label": "Without label" }}
                  value={schedulingFields["inspectionCenter"]["id"]}
                  onChange={handleCenter}
                >
                  {centersCollection.map((inpection_center) => {
                    return (
                      <MenuItem
                        className={classes.menuItem}
                        key={inpection_center.id}
                        // disabled={!inpection_center?.name}
                        value={inpection_center.id}
                      >
                        {inpection_center.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid container>
              <Grid xs={12} item>
                {datesFetching ? (
                  <h3 style={{ textAlign: "center", padding: "5px 0px" }}>
                    Carregando calendário, aguarde...
                  </h3>
                ) : (
                  <DatePicker
                    locale="pt"
                    withPortal
                    selected={currentDate}
                    onChange={(date) => handleScheduling(date)}
                    includeDates={formatDateCalendar(datesCollection)}
                    inline
                  />
                )}
              </Grid>

              <Grid xs={12}>
                <Autocomplete
                  id="combo-box-demo"
                  noOptionsText={"Sem registros"}
                  options={hoursCollection}
                  getOptionLabel={(hour) => (hour ? hour?.substring(0, 5) : "")}
                  style={{ width: "100%" }}
                  disabled={hoursCollection.length === 0}
                  onChange={(event, value) =>
                    setSchedulingFields({
                      ...schedulingFields,
                      hour: value,
                    })
                  }
                  value={schedulingFields.hour || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Horários disponíveis"
                      // fullwidth={true}
                      required
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid xs={12}>
                <LoadingButton
                  loading={schedulingFields.hour == null}
                  variant="contained"
                  className={classes.buttonSchedule}
                  onClick={goToConfirmation}
                >
                  Agendar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Footer classes={classes.footer} />
    </div>
  );
}

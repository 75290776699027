import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Divider } from "@material-ui/core";

// import logoImage from "images/marca_icon.svg";
// import inspectorImage from "images/inspector.png";

import arrowBulletIcon from "images/arrow_bullet.svg";
import degreesImage from "images/360degrees.svg";
import degradeLineImage from "images/degrade_line.svg";
import retangleImage from "images/retangle.svg";
import listaImage from "images/lista.svg";
import engrenagemImage from "images/engrenagem.svg";
import brainImage from "images/brain.svg";
import parallelStripesImage from "images/listras.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#002944",
  },
  inspectionImg: {
    marginTop: "40px",
    maxHeight: "50%",
  },
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  textList: {
    listStyleType: "none",
    "& li": {
      color: "#fff",
      fontSize: "22px",
      textAlign: "left",
      position: "relative",
      marginBottom: "15px",
      "&::before": {
        content: `''`,
        display: "flex",
        background: `url('${arrowBulletIcon}')`,
        backgroundRepeat: "no-repeat",
        width: "25px",
        height: "23px",
        position: "absolute",
        top: "1px",
        left: "-25px",
      },
    },
  },
  title: {
    fontFamily: "Roboto",
    color: "#fff",
    fontSize: "45px",
    fontWeight: "900",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  description: {
    fontFamily: "Roboto",
    color: "#fff",
    fontSize: "22px",
    fontWeight: "500",
    textAlign: "left",
    maxWidth: "80%",
  },
}));

export default function Services() {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid xs={12} item>
        <img
          src={degreesImage}
          alt="degradeLineImage"
          className={classes.inspectionImg}
        />
      </Grid>

      {/* Component */}
      <Grid sm={12} item>
        <Grid container className={classes.container}>
          <Grid sm={4}>
            <img src={listaImage} alt="listaImage" style={{ width: "50%" }} />
          </Grid>
          <Grid sm={4}>
            <ul className={classes.textList}>
              <li>Histórico de leilão</li>
              <li>Restrições, débitos</li>
              <li>Conferência de originalidade</li>
              <li>Analises de carroceria</li>
              <li>Pintura e estrutura do veículo</li>
            </ul>
          </Grid>
        </Grid>
      </Grid>

      {/* Green line */}
      <Grid item xs={12}>
        <img src={retangleImage} style={{ maxWidth: "80%" }} />
      </Grid>

      {/* Component */}
      <Grid sm={12} item>
        <Grid container className={classes.container}>
          <Grid xs={0} sm={1} />
          <Grid sm={4}>
            <Typography variant="h1" className={classes.title}>
              Diagnóstico
            </Typography>
            <Typography variant="body1" className={classes.description}>
              do motor e da
            </Typography>
            <Typography variant="body1" className={classes.description}>
              parte eletrica do automóvel.
            </Typography>
          </Grid>
          <Grid sm={4}>
            <img
              src={engrenagemImage}
              style={{ width: "70%" }}
              alt="listaImage"
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Green line */}
      <Grid item xs={12}>
        <img src={retangleImage} style={{ maxWidth: "80%" }} />
      </Grid>

      {/* Component */}
      <Grid sm={12} item>
        <Grid container className={classes.container}>
          <Grid sm={4}>
            <img src={brainImage} style={{ width: "70%" }} alt="brainImage" />
          </Grid>
          <Grid sm={4}>
            <Typography variant="h1" className={classes.title}>
              Inteligência artificial
            </Typography>
            <Typography variant="body1" className={classes.description}>
              para aprovação ou reprovação do laudo de vistoria, entregando bem
              mais assertividade.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid sm={12} style={{ display: "flex", justifyContent: "end" }} item>
        <img
          src={parallelStripesImage}
          style={{ maxWidth: "50%", marginTop: "-300px" }}
        />
      </Grid>
    </Grid>
  );
}

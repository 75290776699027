import { string } from "prop-types";
import React from "react";
import { IMaskInput } from "react-imask";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  let mask;
  let extra = {};

  switch (props.mask) {
    case "name":
      mask = /^[A-Za-z]+$/;
      break;
    case "phone":
      mask = "(00) 00000-0000";
      break;
    case "cpf":
      mask = "000.000.000-00";
      break;
    case "creditCard":
      mask = "0000 0000 0000 0000";
      break;
    case "creditExpiration":
      mask = "#0/y0";
      extra = {
        definitions: {
          "#": /[0-1]/,
          y: /[2-9]/,
        },
        // <any single char>: <same type as mask (RegExp, Function, etc.)>
        // defaults are '0', 'a', '*'
      };
      break;
    case "date":
      mask = Date;
      extra = {
        pattern: "d{/}`m{/}`Y",
        format: function (date) {
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();

          if (day < 10) day = "0" + day;
          if (month < 10) month = "0" + month;

          return [day, month, year].join("/");
        },
        // define str -> date convertion
        parse: function (str) {
          var dayMonthYear = str.split("/");
          return new Date(
            dayMonthYear[2],
            dayMonthYear[1] - 1,
            dayMonthYear[0]
          );
        },
      };
      break;
    case "cvv":
      mask = "0000";
      break;
    case "licensePlate":
      mask = "aaa-0#00";
      extra = {
        definitions: {
          "#": /[0-9A-Za-z]/,
        },
        // <any single char>: <same type as mask (RegExp, Function, etc.)>
        // defaults are '0', 'a', '*'
      };
      break;
    case "zipCode":
      mask = "00000-000";
      break;
    case "document":
      extra = {
        mask: [{ mask: "000.000.000-00" }, { mask: "00.000.000/0000-00" }],
        dispatch: (appended, dynamicMasked) => {
          const document = (dynamicMasked.value + appended).replace(/\D/g, "");
          if (document.length > 11) {
            return dynamicMasked.compiledMasks[1];
          }
          return dynamicMasked.compiledMasks[0];
        },
      };
    default:
      break;
  }

  return (
    <IMaskInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onAccept={
        // Advance to the next field, if there is one, when mask is completed
        (value, mask) => {
          if (props.mask != "zipCode") {
            if (mask.masked.isComplete) {
              const form = mask.el.input.form;
              const index = Array.prototype.indexOf.call(form, mask.el.input);
              if (form.elements.length - 1 > index) {
                form.elements[index + 1].focus();
              }
            }
          }
        }
      }
      mask={mask}
      {...extra}
    />
  );
}

export function TextMaskName(props) {
  return <TextMaskCustom {...props} mask="name" />;
}

export function TextMaskCPF(props) {
  return <TextMaskCustom {...props} mask="cpf" />;
}

export function TextMaskPhone(props) {
  return <TextMaskCustom {...props} mask="phone" />;
}

export function TextMaskCreditCard(props) {
  return <TextMaskCustom {...props} mask="creditCard" />;
}

export function TextMaskDate(props) {
  return <TextMaskCustom {...props} mask="date" />;
}

export function TextMaskCreditExpiration(props) {
  return <TextMaskCustom {...props} mask="creditExpiration" />;
}

export function TextMaskCreditCardCVV(props) {
  return <TextMaskCustom {...props} mask="cvv" />;
}

export function TextMaskLicensePlate(props) {
  return <TextMaskCustom {...props} mask="licensePlate" />;
}

export function TextMaskZipCode(props) {
  return <TextMaskCustom {...props} mask="zipCode" />;
}

export function TextMaskDocument(props) {
  return <TextMaskCustom {...props} mask="document" />;
}

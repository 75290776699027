import React, { useState } from "react";
import * as Sentry from "@sentry/browser";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/Home";
import Account from "./pages/Account";
import VehicleInspection from "./pages/VehicleInspection";
import Payment from "./pages/Payment";
import Order from "./pages/Order";
import Orders from "./pages/Orders";
import Scheduling from "./pages/Scheduling.js";
import SchedulingConfirmation from "./pages/SchedulingConfirmation";
import ResetPassword from "./pages/ResetPassword.js";
import RequestPasswordReset from "./pages/RequestPasswordReset.js";
import ScrollToTop from "./components/ScrollToTop";
import IntegrationError from './pages/IntegrationError.js'
import "./styles/main.scss";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Noyh",
  },
  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       '@font-face': [noyh],
  //     },
  //   },
  // }
});
const useStyles = makeStyles((theme) => ({}));

// NOTE: Production
if (process.env.NODE_ENV != "development") {
  Sentry.init({
    dsn: "https://87dfd82b840b4907a4e8f588647f70c6@sentry.roadmaps.com.br/42",
  });
}

export default function Public(props) {
  const classes = useStyles(theme);
  const [currentUser, setCurrentUser] = useState(props.currentUser);

  return (
    <Router>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Route exact path="/">
          <Home currentUser={currentUser} />
        </Route>
        <Route exact path="/account">
          <Account currentUser={currentUser} />
        </Route>
        <Route exact path="/vehicle_inspection">
          <VehicleInspection
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
          />
        </Route>
        <Route exact path="/payment">
          <Payment currentUser={currentUser} />
        </Route>
        <Route exact path="/order/:id">
          <Order currentUser={currentUser} />
        </Route>
        <Route exact path="/orders">
          <Orders currentUser={currentUser} />
        </Route>
        <Route exact path="/orders/:id/scheduling">
          <Scheduling currentUser={currentUser} />
        </Route>

        <Route exact path="/scheduling_confirmation">
          <SchedulingConfirmation currentUser={currentUser} />
        </Route>

        <Route exact path="/integration_error">
          <IntegrationError currentUser={currentUser} />
        </Route>

        <Route exact path="/reset_password" component={ResetPassword} />

        <Route exact path="/request_password_reset">
          <RequestPasswordReset />
        </Route>
      </ThemeProvider>
    </Router>
  );
}

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../components/Header'
import VerticalMenu from '../components/VerticalMenu'
import Footer from '../components/Footer'
import { 
  Avatar,
  Button,
  Grid,
  Box,
  Container,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root:{
   height: '220px',
  },
  accountContainer:{
    margin: '50px auto',
  },
  accountTitle:{
    color: '#333333',
    marginTop: 15,
    [theme.breakpoints.up('xl')]: {

    },
    [theme.breakpoints.down('lg')]: {
      // marginLeft: 30,
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      marginLeft: 57,
      fontSize: 24,
    },
    [theme.breakpoints.down('xs')]: {
      // fontSize: 22,
      textAlign: 'center',
      fontSize: 22,

    },
  },
  tabBox:{
    backgroundColor: '#fff',
    padding: '50px 0 50px 0',
  },
}));

export default function Account(props) {

  // Data current user
  const currentUser = props.currentUser && props && props.currentUser || []

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header currentUser={currentUser}/>
      <Container className={classes.accountContainer}>
        
        <Box boxShadow={3}>
          <Grid container>
            <Grid xs={12} style={{backgroundColor: '#fff'}} item>
              <Typography className={classes.accountTitle}>Meus dados</Typography>
            </Grid>
            <Grid xs={12} className={classes.tabBox}>
              <VerticalMenu currentUser={props.currentUser}/>
            </Grid>
          </Grid>
        </Box>

      </Container>
      <Footer/>
    </div>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Grid,
  Typography
} from '@material-ui/core'

const useStyles = makeStyles({
  root: {
  },
  plate: {
    textAlign: 'center',
    fontSize: 60,
    fontFamily: 'Noyh',
    fontWeight: 900,
  },
  description:{
    textAlign: 'center',
    fontSize: '50px',
    maxWidth: '440px',
    margin: '0 auto',
  }
});

export default function Vehicle({currentVehicle}) {
  const classes = useStyles();
  let licensePlate = currentVehicle && currentVehicle || 'Veículo indisponível';
  return (
    <Grid className={classes.root} xs={12} container>
      <Grid xs={12} item>
        <Typography className={classes.plate}>{`${licensePlate}`}</Typography>
      </Grid>
      {/* <Grid xs={12} className={classes.description} item>
        <Typography>
          Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit. In dapibus eleifend nisi, sed semper
          ligula laoreet aliquam.
        </Typography>
      </Grid> */}
    </Grid>
  );
}

import React, { useEffect, useState } from "react";
import { useHistory, useLocation, Link, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Typography, Paper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Report from "../components/Report";
import OrderInformation from "../components/OrderInformation";
import LoadingButton from "../components/LoadingButton";
import { styles } from "../../components/FileField";

const useStyles = makeStyles((theme) => ({
  root: {},
  orderContainer: {
    display: "flex",
    justifyContent: "center",
  },
  orderBox: {
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  descriptionsText: {
    textAlign: "left",
    fontWeight: "normal",
    color: "#536777",
    marginLeft: 20,
    marginTop: 15,
    fontSize: 20,
  },
  orderNumber: {
    textAlign: "left",
    fontSize: 32,
    fontWeight: 900,
    marginLeft: 20,
  },
  vehiclePlate: {
    textAlign: "left",
    fontSize: 32,
    fontWeight: 900,
    marginLeft: 20,
  },
  totalDebt: {
    textAlign: "left",
    fontSize: 32,
    fontWeight: 900,
    color: "#05CC98",
    opacity: 1,
    letterSpacing: 0,
    marginLeft: 20,
  },
  paymentTypeText: {
    textAlign: "left",
    fontSize: 18,
    color: "#676870",
    textTransform: "uppercase",
    marginTop: 15,
    marginLeft: 20,
  },
  cardLastDigits: {
    textAlign: "left",
    fontSize: 17,
    fontWeight: "bold",
    marginLeft: 20,
  },
  parcels: {
    textAlign: "left",
    fontSize: 18,
    fontWeight: 900,
    marginLeft: 20,
  },
  paidDebts: {
    alignItems: "center",
    marginTop: 15,
  },
  button: {
    marginBottom: "25px",
    [theme.breakpoints.up("xl")]: {
      width: "190px",
      fontSize: "18px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "160px",
      fontSize: "14px",
    },
    [theme.breakpoints.down("md")]: {
      width: "140px",
      fontSize: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "130px",
      fontSize: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "110px",
    },
  },
  descriptionDebt: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  valueDebt: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  orderButtons: {
    width: "200px",
    height: "45px",
    marginBottom: "10px",
    borderRadius: "10px",
    "& span, a": {
      color: "#fff",
      fontSize: "18px",
      textTransform: "none",
      textDecoration: "unset",
    },
  },
  scheduleButton: {
    minWidth: "50%",
    backgroundColor: "#239CC4",
    marginTop: "30px",
    borderRadius: "10px",
    opacity: 1,
    "& span": {
      color: "#fff",
      fontSize: 18,
      textTransform: "none",
    },
  },
  orderInfoBox: {
    height: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  downloadButton: {
    width: "200px",
    backgroundColor: "#239CC4 !important",
    marginTop: "30px",
    marginBottom: "20px",
    borderRadius: "10px",
    opacity: 1,
    "& span": {
      color: "#fff",
      fontSize: 18,
      textTransform: "none",
    },
  },
}));

export default function Order(props = {}) {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  // currentUser
  const currentUser = (props.currentUser && props.currentUser) || {};
  const [orderInformation, setOrderInformation] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  // Check if the user is logged in
  useEffect(() => {
    console.log("orderInformation", orderInformation);
    if (orderInformation?.payment.segsale_integration_status === "ERRO") {
      history.push("/integration_error");
    } else if (
      orderInformation?.payment.status === "paid" &&
      orderInformation?.schedule_confirmation.inspection_center_name == null &&
      orderInformation?.schedule_confirmation.presential_scheduling_datetime ==
        null
    ) {
      history.push({
        pathname: `/orders/${orderInformation?.inspection_id?.value}/scheduling`,
        state: {
          currentUser: currentUser,
          // vehicleInspectionId: vehicleInspectionId
        }
      });
    }
  }),
    [currentUser.id, history];

  // Verification of orders paid
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/api/v1/vehicle_inspection/${id}`)
      .then((res) => {
        let response = res?.data;
        setOrderInformation(response?.report);
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      <Header currentUser={currentUser} />
      {orderInformation?.report_file_url == null ? (
        <div className={classes.orderInfoBox}>
          <OrderInformation data={orderInformation} />
        </div>
      ) : (
        <>
          <Report
            documentTitle={"Laudo de vistoria cautelar"}
            reportContent={orderInformation}
          />
          <LoadingButton
            variant="contained"
            className={classes.downloadButton}
            onClick={() => {
              window.open(orderInformation.report_file_url, "_blank");
            }}
          >
            Download do Laudo
          </LoadingButton>
        </>
      )}
      <Footer />
    </div>
  );
}

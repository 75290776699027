import React, { useEffect } from 'react';
import { PDFViewer, Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
const classes = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title:{
   textAlign: 'center',
  },
  section:{
    color: '#002f47',
  },
  downloadButton:{
    // border: '1px solid red',
  }
});


function InspectionDocument(props) {
  // Constants
  const generalInspectionInfo = props.occultGeneralInspectionInfo && props && props.occultGeneralInspectionInfo || {};
  const currentVehicle = props.occultCurrentVehicle && props && props.occultCurrentVehicle || {};
  const report =  props.occultReport && props && props.occultReport || {};

  // Creation of the PDF document
  const document = (
    <Document style={classes.body}>
      <Page size="A4">
        <View>
          <Text style={classes.title}>
            { props.documentTitle ? props.documentTitle : `` }
          </Text>
        </View>
        <View>
          <Text style={classes.section}>
            Informações Gerais
          </Text>
          { generalInspectionInfo &&
              generalInspectionInfo.map((itens) =>
              <View>
                <Text>{itens.key}</Text>
                <Text>{itens.value}</Text>
              </View>
          )}
        </View>
        <View>
          <Text style={classes.section}>
            Veículo
          </Text>
        </View>
        { currentVehicle &&
          currentVehicle.map((itens) =>
          <View>
            <Text>{itens.key}</Text>
            <Text>{itens.value}</Text>
          </View>
        )}
        <View>
          <Text style={classes.section}>
            Informações da Vistoria
          </Text>
        </View>
        { report &&
          report.map((itens) =>
          <View>
            <Text>{itens.key}</Text>
            <Text>{itens.value}</Text>
          </View>
        )}
      </Page>
    </Document>
  )
  
  return (
    <div>
      {/* PDF viewing */}
      <PDFViewer width="55%" height={700} key={Math.random()}>
        { document }
      </PDFViewer>
      {/* Inspection download button in PDF */}
      { generalInspectionInfo.length > 0 && report.length > 0 && currentVehicle.length > 0 && props.enableDownload && 
      <PDFDownloadLink document={ document } fileName="vistoria_cautelar.pdf" style={classes.downloadButton}>
        {({ blob, url, loading, error }) => (loading ? 'Carregando documento..' : 'Fazer download')}
      </PDFDownloadLink>
      }
    </div>
  );
}

export default InspectionDocument;
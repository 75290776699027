import * as React from "react";
function SvgComponent1(props) {
  return (
    <svg
      data-name="Component 29 \u2013 2"
      xmlns="http://www.w3.org/2000/svg"
      width={150.318}
      height={193.648}
      transform="scale(0.10)"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#00b26c" />
          <stop offset={1} stopColor="#239cc4" />
        </linearGradient>
      </defs>
      <path
        data-name="Path 2365"
        d="M3578.664 8743l-45.455-96.057h-.973l46.031 97.274h.8l92.313-195.926h-.973z"
        transform="translate(-3532.236 -8548.287)"
        fill="url(#prefix__a)"
      />
    </svg>
  );
}

export default SvgComponent1;

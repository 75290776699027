import React from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css'
import '../styles/override-card-form.scss'

export default function PaymentForm(props){
  
  return (
    <div id="PaymentForm">
      <Cards
        number={props.card.number}
        name={props.card.name}
        cvc={props.card.cvc}
        expiry={props.card.expiry}
        focused={props.card.focus}
        locale={{ valid: 'Validade' }}
        placeholders={{ name: 'NOME NO CARTÃO'}}
      />
    </div>
  );
}
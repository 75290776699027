import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { 
  Box,
  Typography,
  Grid,
  Button,
  Hidden,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '425px',
    [theme.breakpoints.up('md')]: {
      margin: '20px 30px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '20px 30px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '20px 30px',
    },
  },
  orderListContainer:{
    backgroundColor: '#FFF',
    boxShadow: '16px 16px 48px #00000019',
  },
  rows:{
    border: '1px solid #F4F4F4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& h6':{
      color: '#333333',
      fontSize: '18px',
      lineHeight: '50px',
      fontFamily: 'Source Sans Pro',
      fontWeight: 'normal',
      opacity: '0.5'
    },
    '& p':{
      color: '#333333',
      fontSize: '18px',
      lineHeight: '60px',
      fontFamily: 'Source Sans Pro',
      fontWeight: 'normal',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      },
    },
  },
  title:{
    fontSize: 24,
    marginTop: 20,
    marginBottom: 20,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    letterSpacing: '0px',
    color: '#333333',
    opacity: 1,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      marginLeft: 35,
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 18,
    },
  },
  payButton:{
    width: '65%',
    height: '35px',
    backgroundColor: '#239CC4',
    borderRadius: '13px',
    opacity: 1,
    '& span':{
      marginTop: '2px',
      color: '#fff',
      fontSize: 16,
      textTransform: 'none',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        fontSize: 12,
      },
    }
  },
  moreDataButton:{
    height: 50,
    width: '200px',
    backgroundColor: '#239CC4 !important',
    marginTop: '30px',
    marginBottom: '20px',
    borderRadius: '10px',
    opacity: 1,
    '& span':{
      color: '#fff',
      fontSize: 18,
      textTransform: 'none'
    }
  },
}));

export default function OrderList() {
  const classes = useStyles();
  let history = useHistory();

  // Number skeleton lines
  const skeletonLines = 10

  // Disable button
  const [showMoreBtn, setShowMoreBtn] = React.useState(true);

  // List of paid orders
  const [orderList, setOrderList] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(1);

  // Loading
  const [loading, setLoading] = React.useState(true);

  function goToOrder(orderId){
    history.push({ pathname: `/order/${orderId}`})
  }

  // BRL currency formatting
  function formatCurrency(value) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
  }

  // Load more records
  function handleButtonClick(){
    // Ocorre bug de repetição ao exibir +10 de itens.
    setPageNumber(pageNumber + 1);
  }
  
  function getOrders(){
    setLoading(true);
    axios.get(`/api/v1/vehicle_inspection.json?vehicle=true&page=${pageNumber}`,
    ).then(res => {
      setShowMoreBtn(res.data.length > 0 ? true : false);
      setOrderList(orderList.concat(res.data));
    })
    .catch(error => {
      console.log(error);
    })
    .finally(() => {
      setLoading(false);
    });
  }
  // Verification of orders paid
  useEffect(() => {
    getOrders();
  },[pageNumber])

  return (
    <div className={classes.root}>
      <Grid className={classes.orderListContainer} container>
        <Grid xs={12} item>
          <Typography className={classes.title} variant="h1">
            Meus pedidos
          </Typography>
        </Grid>
        <Hidden xsDown>
          {[{ num: 3 }, { num: 3 }, { num: 2 }, { num: 2 }, { num: 2 }].map(
            (grid) => (
              <Grid item sm={grid["num"]} className={classes.rows}>
                <Typography variant="subtitle1">{"\u00A0"}</Typography>
              </Grid>
            )
          )}
          <Grid item sm={3} className={classes.rows}>
            <Typography variant="subtitle1">Número da pedido</Typography>
          </Grid>
          <Grid item sm={3} className={classes.rows}>
            <Typography variant="subtitle1">Veículo</Typography>
          </Grid>
          <Grid item sm={2} className={classes.rows}>
            <Typography variant="subtitle1">Status</Typography>
          </Grid>
          <Grid item sm={2} className={classes.rows}>
            <Typography variant="subtitle1">VALOR</Typography>
          </Grid>
          <Grid item sm={2} className={classes.rows} />
        </Hidden>

        <Hidden smUp>
          {[{ num: 2 }, { num: 4 }, { num: 2 }, { num: 4 }].map((grid) => (
            <Grid item xs={grid["num"]} className={classes.rows}>
              <Typography variant="subtitle1">{"\u00A0"}</Typography>
            </Grid>
          ))}
          <Grid item xs={2} className={classes.rows}>
            <Typography variant="subtitle1">Status</Typography>
          </Grid>
          <Grid item xs={4} className={classes.rows}>
            <Typography variant="subtitle1">Veículo</Typography>
          </Grid>
          <Grid item xs={2} className={classes.rows}>
            <Typography variant="subtitle1">Valor</Typography>
          </Grid>
          <Grid className={classes.rows} item xs={4}></Grid>
        </Hidden>
        {!loading ? (
          orderList.map((order) => {
            return (
              <Grid xs={12} item>
                <Grid container key={order.id}>
                  <Hidden xsDown>
                    <Grid item xs={3} className={classes.rows}>
                      <Typography variant="body1" Wrap>
                        {order.pg_token}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.rows}>
                      <Typography variant="body1" Wrap>
                        {order.vehicle.license_plate}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.rows}>
                      {[
                        { name: "Processando", status: "processing" },
                        { name: "Autorizado", status: "authorized" },
                        { name: "Pago", status: "paid" },
                        {
                          name: "Transação estornada por completo",
                          status: "refunded",
                        },
                        { name: "Transação recusada", status: "refused" },
                        {
                          name: "Transação sofreu chargeback",
                          status: "chargedback",
                        },
                        {
                          name: "Transação encaminhada para a análise manual",
                          status: "analyzing",
                        },
                        {
                          name: "Transação pendente de revisão manual",
                          status: "pending_review",
                        },
                      ].map((field) => (
                        <Typography variant="body1">
                          {order.status == field.status && field.name}
                        </Typography>
                      ))}
                    </Grid>
                    <Grid item xs={2} className={classes.rows}>
                      <Typography variant="body1" Wrap>
                        {order.debt_total && formatCurrency(order.debt_total)}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.rows}>
                      <Button
                        className={classes.payButton}
                        onClick={() => goToOrder(order.id)}
                        variant="contained"
                      >
                        Detalhar
                      </Button>
                    </Grid>
                  </Hidden>

                  <Hidden smUp>
                    <Grid item xs={2} className={classes.rows}>
                      {[
                        { name: "Processando", status: "processing" },
                        { name: "Autorizado", status: "authorized" },
                        { name: "Pago", status: "paid" },
                        {
                          name: "Transação estornada por completo",
                          status: "refunded",
                        },
                        { name: "Transação recusada", status: "refused" },
                        {
                          name: "Transação sofreu chargeback",
                          status: "chargedback",
                        },
                        {
                          name: "Transação encaminhada para a análise manual",
                          status: "analyzing",
                        },
                        {
                          name: "Transação pendente de revisão manual",
                          status: "pending_review",
                        },
                      ].map((field) => (
                        <Typography variant="body1">
                          {order.status == field.status && field.name}
                        </Typography>
                      ))}
                    </Grid>
                    <Grid item xs={4} className={classes.rows}>
                      <Typography variant="body1" Wrap>
                        {order.vehicle && order.vehicle.license_plate}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.rows}>
                      <Typography variant="body1" Wrap>
                        {order.debt_total && formatCurrency(order.debt_total)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.rows}>
                      <Button
                        className={classes.payButton}
                        onClick={() => goToOrder(order.id)}
                        variant="contained"
                      >
                        Detalhar
                      </Button>
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Box sx={{ width: "95%" }} style={{ margin: "0 auto" }}>
            {[...Array(skeletonLines)].map((field) => (
              <Skeleton animation="wave" height={80} />
            ))}
          </Box>
        )}
      </Grid>
      <div style={{ margin: "0 auto" }}>
        {showMoreBtn && (
          <Button
            className={classes.moreDataButton}
            onClick={() => handleButtonClick()}
          >
            Mostrar mais
          </Button>
        )}
      </div>
    </div>
  );
}